section.aboutUs {
    font-size: 23px;
    color: #fff;
    margin-top: -187px;
    width: 100%;
    padding-top: 227px;
    padding-bottom: 300px;
  }
  section.aboutUs h2 {
    font-size: 60px;
    line-height: 78px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 0;
  }
  
  section.aboutUs img {
    position: absolute;
    right: -93px;
    bottom: -52px;
    width: 63%;
    z-index: 0;
  }
  
  header img {
    z-index: 8;
  } 
  
  a.btn-1 {
    width: 200px;
    line-height: 55px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    border-radius: 28px;
    background: #03c0f2;
    padding: 12px 36px;
    text-decoration: none;
  }

  section.our-agency{
    padding-top: 12rem;
  }
  
  section.about-us-content p {
    position: relative;
    font-size: 16px;
    line-height: 35px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    padding-bottom: 34px;
  }
  
  section.our-agency .row {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 34px;
  }
  
  section.our-agency img {
    width: 100%;
  }
  
  section.about-us-content * {
    box-sizing: border-box;
  }
  
  section.about-us-content h2 {
    font-size: 25px;
  }
  
  section.our-agency h2 {
    line-height: 55px;
    color: #2d2d43;
    font-size: 40px;
    font-weight: 600;
    margin-top: 0;
  }
  
  section.our-agency .image-section {
    padding-top: 28px;
  }
  
  span.highlight-theme-color {
    color: #03c0f2;
  }
  span.highlight-theme-color:before {
    opacity: 0;
  }
  
  .oa-left-content p {
    margin: 0;
    padding: 0;
  }
  
  .oa-left-content p {
    -webkit-box-shadow: 0px 15px 65px 0px rgb(0 0 0 / 10%);
    box-shadow: 0px 15px 65px 0px rgb(0 0 0 / 10%);
    background-color: #FFFFFF;
    width: 100%;
    max-width: 319px;
    padding: 25px 30px!important;
    margin-bottom: 20px;
  }
  
  .oa-left-content p span {
    margin-right: 8px;
    background: #03c0f2;
    display: inline-block;
    color: #fff;
    padding: 5px 17px;
    border-radius: 57% 43% 30% 70% / 47% 29% 71% 53%;
    clear: both;
  }
  
  .oa-left-content p:nth-of-type(1) {
    margin-left: calc(42px * 1);
  }
  
  .oa-left-content p:nth-of-type(2) {
    margin-left: calc(42px * 2);
  }
  
  .oa-left-content p:nth-of-type(3) {
    margin-left: calc(42px * 3);
  }
  
  .oa-left-content p:nth-of-type(4) {
    margin-left: calc(42px * 4);
  }
  
  .oa-left-content {
    position: relative;
  }
  
  .oa-left-content .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(1.1);
    background-size: auto 86%;
    background-position: center!important;
  }
  
  .oa-left-content p {
    font-weight: bold!important;
  }
  
  section.about-us-content h2 {
    line-height: 55px;
    color: #2d2d43;
    font-size: 40px;
    font-weight: 600;
    margin-top: 0;
  }
  
  section.history.about-us-content {
    position: relative;
  }
  
  section.history.about-us-content .row {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 39px;
  }
  
  section.history.about-us-content .row img {
    width: 100%;
  }
  
  section.history.about-us-content:before {
    content: "";
    width: 50%;
    height: 100%;
    position: absolute;
    background: #f7fafb7d;
    border-radius: 57% 43% 30% 70% / 47% 29% 71% 53%;
    transform: rotate(-45deg);
    left: -158px;
    top: 98px;
  }
  
  section.history.about-us-content > div {
    z-index: 8;
    position: relative;
  }
  
  section.history.about-us-content {
    margin-top: 90px;
  }
  
  section.history.about-us-content h2 {
    margin-top: -18px;
  }
  
  section.history.about-us-content h4 {
    color: #2cd3ff;
  }
  
  section.history.about-us-content .image-section {
    margin-top: 45px;
  }