.cntNewProcess-2 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    filter: url(#goo);
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: 0px 40px;
    margin-top: 201px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 44px;
}

.cntNewProcess-2:before {
    content: "";
    width: 100%;
    height: 67px;
    position: absolute;
    background: #10c9fb;
    border-radius: 100px;
    z-index: -1;
}

.innerProcessBox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.innerProcessBox:before {
    content: "";
    width: 150px;
    height: 150px;
    position: absolute;
    background: #10c9fb;
    transform: rotate(45deg);
}

.processBox-1:nth-child(odd) .innerProcessBox:before {
    border-radius: 17px 0px 58px;
}

.processBox-1:nth-child(even) .innerProcessBox:before {
    border-radius: 58px 0px 17px;
}

.processBox-1 i {
    background: #ede9e9;
    color: #212f67;
    width: 104px;
    height: 104px;
    border-radius: 100%;
    font-size: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 3px 4px 1px #fff, 9px 12px 15px #0000006b;
}
 
.innerProcessBox  h3 {
    position: absolute;
    top: -162px;
    background: #10c9fb;
    text-transform: uppercase;
    color: #fff;
    padding: 6px 27px;
    border-radius: 10px;
}

.processBox-1:nth-child(even) h3 {
    top: auto;
    bottom: -164px;
}

.cntNewProcess {
    display: none;
}

.processBox-1:hover {opacity: 1;}

.cntNewProcess-2 .processBox-1 {
    transition: 0.5s;
}

.cntNewProcess-2:hover .processBox-1 {
    opacity: 0.1;
}

.processBox-1:hover {
    opacity: 1!important;
}

.processBox-1 h3 {
    transition: 0.5s  cubic-bezier(0,2,.61,-0.05);
    transition-delay: 0.2s;
}

.processBox-1:nth-child(odd):hover h3 {
    top: -177px;
}

.processBox-1:nth-child(even):hover h3 {
    bottom: -177px;
}

.cntNewProcess-2:hover {
    background: #03c0f2!important;
}


@media (max-width:767px){
    .cntNewProcess-2:before{
        opacity:0;
    }
    .cntNewProcess-2 .innerProcessBox {
        height: 200px;
    }
    .processBox-1:nth-child(odd) .innerProcessBox:before, .processBox-1:nth-child(even) .innerProcessBox:before {
        border-radius: 17px 26px;
    }
    .cntNewProcess-2 {
        display: flex;
        flex-direction: column;
        margin-top: 99px!important;
    }
    .processBox-1:nth-child(even) h3 {
        top: -164px;
        bottom: auto;
    }
    .innerProcessBox h3 {
        top: -84px;
    }
    .processBox-1:nth-child(2) {
        margin-bottom: 88px;
        margin-top: 85px;
    }
    
    .processBox-1:nth-child(2) h3 {
        top: -66px!important;
    }
    
    .processBox-1:nth-child(4) h3 {
        top: -66px;
    }
    
    .processBox-1:nth-child(4) {
        margin-bottom: 86px;
        margin-top: 80px;
    }

    .processBox-1:nth-child(3) h3 {
        top: -66px!important;
    }

    .processBox-1:nth-child(1) h3 {
        top: -66px!important;
    }
    .processBox-1:nth-child(5) h3 {
        top: -66px!important;
    }
    section#process > .container :is(h2, p) {
        padding: 0px 0px;
    }
    .cntNewProcess-2:hover {
        background: transparent!important;
    }
    .cntNewProcess-2:hover .processBox-1 {
        opacity: 1!important;
    }
    .processBox-1 h3 {
        height: 30px;
    }
    .inner-services:nth-child(1) img{
        width:150px;
    }
    
    .services-201 img{
        max-width:152px;
    }
    
    .inner-services:nth-child(3) > div img{
        width:179px;
    }
    .services-201 {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        gap: 43px;
    }
    .services-201 {
        margin-right: -28px;
    }
    section.services h2 {
        margin-left: 0!important;
        padding-left: 0!important;
        padding-right: 0!important;
        margin-right: 0!important;
    }
} 