.sliderBanner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.sliderBanner img {
    width: 100%;
    left: 0;
    position: absolute;
    top: 0;
}

.slider-box img {
    max-width: 100%;
}

.slider-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
}

.slider-box h2 {
    font-size: 58px;
    line-height: 1.2em;
    margin-top: 127px;
}

.slider-box h2 + p {
    font-size: 21px;
}

.slide-3 .sliderBanner img {
    filter: hue-rotate(81deg);
}

.slide-3 .slider-box img {
    margin-top: 53px;
}

.slide-4 .sliderBanner img {
    width: 62%;
    right: 0;
    left:auto;
}

.slide-4 .slider-box img {
    margin-left: -78px;
    transform: translateY(50px);
}