section.services-header h2 {
    font-size: 60px;
    line-height: 78px;
    font-weight: 600;
    color: #2d2d43;
    text-align: center;
}

section.services-header {
    margin-top: -167px;
    position: relative;
    padding-top: 135px;
}

img.top-right {
    position: absolute;
    right: 0;
    top: 0;
    left: auto;
}

.bg {
    position: absolute;
    width: 100%;
    top: -51px;
}

section.servicesGridSection .row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}



.services-inner-wrapper h4 {
    margin-bottom: 0;
    color: #fff;
    margin-top: -10px;
}

.service_content_box{
    max-width: 900px;
    text-align: center;
    margin: auto;

}

.service_content_box >p{
    font-size: 16px;
    line-height: 35px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);

   
}

.service_content_box p > a{
    color: #03c0f2;
    
}

#ServiceShapeOne tspan { white-space:pre }
#ServiceShapeOne .s0 { opacity: .3;fill: #ff5733 } 
#ServiceShapeOne .s1 { fill: url(#grd11) } 
#ServiceShapeOne .s2 { fill: url(#grd12) } 
#ServiceShapeOne .s3 { fill: url(#grd13) } 
#ServiceShapeOne .s4 { fill: url(#grd14) } 
#ServiceShapeOne .s5 { fill: url(#grd15) } 
#ServiceShapeOne .s6 { opacity: .7;fill: #ffffff } 

#ServiceShapeTwo tspan { white-space:pre }
#ServiceShapeTwo .s0 {  opacity: .3; fill: url(#grd21) } 
#ServiceShapeTwo .s1 { fill: url(#grd22) } 
#ServiceShapeTwo .s2 { fill: url(#grd23) } 
#ServiceShapeTwo .s3 { fill: url(#grd24) } 
#ServiceShapeTwo .s4 { opacity: .7;fill: #ffffff } 
#ServiceShapeTwo .s5 { fill: url(#grd25) } 
#ServiceShapeTwo .s6 { fill: url(#grd26) } 


#ServiceShapeThree tspan { white-space:pre }
#ServiceShapeThree .s0 { opacity: .3; fill: url(#grd31) } 
#ServiceShapeThree .s1 { fill: url(#grd32) } 
#ServiceShapeThree .s2 { fill: url(#grd33) } 
#ServiceShapeThree .s3 { fill: url(#grd34) } 
#ServiceShapeThree .s4 { fill: url(#grd35) } 
#ServiceShapeThree .s5 { fill: url(#grd36) } 
#ServiceShapeThree .s6 { opacity: .7;fill: #ffffff } 


#ServiceShapeFour tspan { white-space:pre }
#ServiceShapeFour .s0 { opacity: .3; fill: url(#grd41) } 
#ServiceShapeFour .s1 { fill: url(#grd42) } 
#ServiceShapeFour .s2 { fill: url(#grd43) } 
#ServiceShapeFour .s3 { fill: url(#grd44) } 
#ServiceShapeFour .s4 { fill: url(#grd45) } 
#ServiceShapeFour .s5 { fill: url(#grd46) } 
#ServiceShapeFour .s6 { opacity: .7;fill: #ffffff } 

#ServiceShapeFive tspan { white-space:pre }
#ServiceShapeFive .s0 { opacity: .3; fill: url(#grd51) } 
#ServiceShapeFive .s1 { fill: url(#grd52) } 
#ServiceShapeFive .s2 { fill: url(#grd53) } 
#ServiceShapeFive .s3 { fill: url(#grd54) } 
#ServiceShapeFive .s4 { fill: url(#grd55) } 
#ServiceShapeFive .s5 { fill: url(#grd56) } 
#ServiceShapeFive .s6 { opacity: .7;fill: #ffffff } 


#ServiceShapeSix tspan { white-space:pre }
#ServiceShapeSix .s0 { opacity: .3; fill: url(#grd61) } 
#ServiceShapeSix .s1 { fill: url(#grd62) } 
#ServiceShapeSix .s2 { fill: url(#grd63) } 
#ServiceShapeSix .s3 { fill: url(#grd64) } 
#ServiceShapeSix .s4 { fill: url(#grd65) } 
#ServiceShapeSix .s5 { fill: url(#grd66) } 
#ServiceShapeSix .s6 { opacity: .7;fill: #ffffff } 


.content-service a :is(h4,img) {
    position: relative;
}
.content-service a:before {
    content: "";
    left: -6px;
    width: 107px;
    height: 106px;
    position: absolute;
    background: #ffffffe3;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    top: -8px;
    transition: 1.5s;
}
.content-service a {
    position: relative;
}

.service-section-2:nth-child(1) .content-service a:before {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}

.service-section-2:nth-child(2) .content-service a:before {
    border-radius: 47% 53% 39% 61% / 63% 80% 20% 37% ;
}

.service-section-2:nth-child(4) .content-service a:before {
    border-radius: 80% 20% 70% 30% / 63% 68% 32% 37% ;
}


.service-section-2:hover .content-service a:before {
    border-radius: 100%;
}