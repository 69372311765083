.animatedSlider-wrapper{
    position: relative;
}

.homePageSlider {
    margin-top: -170px;
}

.animatedSlider-wrapper {
    position: relative;
    padding-top: 125px;
}

@media (max-width:1200px) and (min-width:1100px){
    .animatedSlider-wrapper {
      height: 70vh!important;
    }
    .slider-box h2 {
        margin-top: 36px!important;
    }
    .homePageSlider {
        margin-top: -167px;
    }
    .animatedSlider-wrapper {
        position: relative;
        padding-top: 147px;
    }
    div#slide-4 h2 {
        margin-top: 113px!important;
    } 
  }
 

  @media (min-width:800px) and (max-width:850px){
    .slider-box h2 {
        font-size: 58px!important;
        line-height: 1.2em!important;
        margin-top: 55px!important;
    }
    
    .animatedSlider-wrapper {
        padding-top: 125px!important;
        height: 514px!important;
    }
    .slide-4 .sliderBanner img {
        width: 92%!important;
        right: 0!important;
        left: auto!important;
    }
    
    .slide-4 .slider-box h2 {
        margin-top: 51px!important;
    }
    
    .slide-4 .slider-box img {
        max-width: 100%!important;
        margin-top: 0px!important;
        margin-left: -23px;
    }
    div#slide-2 .slider-box img {
        margin-top: 85px;
    }
    div#slide-2 img.banner-2 {
        top: auto;
        bottom: 0;
        width: 125%;
    }
    div#slide-3 img.banner-2 {
        top: auto;
        bottom: 0;
    }
    div#slide-3 .slider-box img {
        margin-left: 28px;
    }
  }

  @media (max-width:767px){
    .slider-box {
        grid-template-columns: 1fr;
    }
    .slide-4 .slider-box img {
        width: 61%!important;
        margin: auto!important;
    }
    .slider-box img {
        width: 61%!important;
        margin: auto!important;
    }
    
    .slider-box h2 {
        font-size: 40px;
        margin-top: 73px;
        text-align: center;
        margin-bottom: 0;
    }
    .animatedSlider-wrapper {
        position: relative;
        padding-top: 125px;
        height: 580px;
    }
    .slider-box {
        text-align: center;
    }
    div#slide-2 .slider-box img {
        margin-top: 29px!important;
        margin-bottom: -46px!important;
    }
    div#slide-2 img.banner-2 {
        top: auto;
        bottom: 0;
    }
    div#slide-3 h2 {
        margin-top: 20px;
    }
    div#slide-3 .slider-box img {
        margin-top: 42px!important;
    }
    div#slide-3 img.banner-2 {
        top: auto;
        bottom: 0;
    }
  }