@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Philosopher:wght@400;700&display=swap');
/*
h1,h2,h3,h4,h5,h6{
  font-family: 'Philosopher', sans-serif;
}
*/
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

header img {
  width: 180px;
}

nav {
  display: flex;
}

body {
  padding: 0;
  margin: 0;
}

.slick-prev {
  left: 19px!important;
}

.slick-next {
  right: 0!important;
}

nav a {
  font-size: 18px;
  line-height: 26px;
  color: #38384f;
  text-decoration: none;
  font-weight: 500;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 38px;
  justify-content: flex-end;
  width: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header {
  padding: 33px 0px;
}

nav li a.active:before, nav li a:hover:before {
  opacity: 1;
  width:100%;
}

nav li a:before {
  content: '';
  height: 5px;
  opacity: 0;
  position: absolute;
  background: rgb(1 195 245 / 50%);
  border-radius: 2.5px;
  left: 0;
  z-index: -1;
  width: 0%;
  bottom: 3px;
  transition:0.5s;
}

nav li a {
  position: relative;
}

.awssld__content {
    background: #fff!important;
}

.slider-content h4 {
  font-size: 22px;
  line-height: 33px;
  font-weight: 400;
  color: rgb(0 0 0 / 40%);
  margin-top: -25px;
}

.slider-content h2 {
  font-size: 60px;
  line-height: 78px;
  font-weight: 600;
  color: #2d2d43;
}

:is(.slider-content, .portfolio-button) a {
  width: 200px;
  line-height: 55px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  border-radius: 28px;
  background: #03c0f2;
  padding: 12px 36px;
  text-decoration: none;
}

.slider-banner .image-section img {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.slider-banner .image-section {
  right: 0;
  top: 141px;
  position: absolute;
  width: 100%;
  height: 519px;
}

.image-section img:first-child {
  margin-top: -28px;
}

.container h2 span {
  position: relative;
}
.container h2 span:before {
  content: '';
  width: 97%;
  height: 7px;
  position: absolute;
  background: rgb(1 195 245 / 50%);
  border-radius: 2.5px;
  left: 3px;
  bottom: 17px;
  z-index: -1;
}


.slider-content {
  
  background-size: contain;
} 

.slider-banner {
  margin-top: -177px;
}

header.header-section {
  z-index: 99999;
  position: relative;
}

.contents-side {
  position: relative;
  z-index: 1;
}


.page-not-found .row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 55px;
}

.page-not-found img {
  max-width: 100%;
}

.page-not-found .content-section h1 {
  font-size: 201px;
  line-height: 20px;
  color: #02c1f5;
  margin-bottom: 94px;
}

.page-not-found .content-section p {
  font-size: 22px;
  color: #000000a8;
}

.page-not-found .content-section a {
  background: #02c1f5;
  color: #fff;
  text-decoration: none;
  padding: 12px 45px;
  border-radius: 100px;
  display: inline-block;
  margin-top: 17px;
}

.page-not-found .content-section em {
  font-weight: bold;
  text-transform: capitalize;
}

.slider-content {
  min-height: 406px;
}

button.slick-arrow.slick-prev {
  z-index: 99;
  border-left: 3px solid #04b9f4;
  border-bottom: 3px solid #04b9f4;
  transform: rotate(45deg);
  color: transparent;
  opacity: 0;
  -webkit-text-fill-color: transparent;
}

button.slick-arrow.slick-next {
  border-right: 3px solid #04b9f4;
  border-top: 3px solid #04b9f4;
  transform: rotate(
45deg);
  position: absolute;
  margin-right: 13px;
  z-index: 99;
  color: transparent;
  -webkit-text-fill-color: transparent;
  opacity: 0;
}

.slider-banner:hover button.slick-arrow {
  opacity: 1;
}

h2.heading {
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
  color: #2d2d43;
}

section#process h2 {
  text-align: center;
}

section#process h2 + p {
  font-size: 20px;
  line-height: 33px;
  font-weight: 400;
  color: rgba(37, 47, 62, 0.8);
  text-align: center;
}

.process-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 56px;
}

.box-item {
  border: 3px solid #03c0f2;
  width: 188px;
  margin: auto;
  height: 188px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  flex-direction: column;
}

.box-item img {
  width: 60px;
}

section#about-us .row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

section#about-us img {
  max-width: 100%;
}

section#about-us {
  padding: 2% 0% 13%;
}

section#about-us img {
  transform: translateX(-81px) translateY(81px);
}

section#about-us h2 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
  color: #2d2d43;
}

section#about-us p {
  font-size: 16px;
  line-height: 35px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  padding-bottom: 34px;
}

.content-section h2 + p {
  position: relative;
}

section#about-us .content-section h2 + p:before {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  border-left: 3px solid;
  border-bottom: 3px solid;
  border-color: #03c0f2;
  left: 0;
  top: 12px;
}

section#about-us .content-section h2 + p:after {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  border-right: 3px solid;
  border-top: 3px solid;
  border-color: #03c0f2;
  left: -10px;
  top: 23px;
}

section#about-us p {
  padding-left: 51px;
}

.more-about-us {
  position: absolute;
  right: 0;
  color: #fff;
  background: #03c0f2;
  padding: 36px 63px;
  display: flex;
  flex-direction: column;
  width: 265px;
  overflow: hidden;
}

.more-about-us span:nth-child(1) {
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  color: #ffffffa8;
}

.more-about-us span:nth-child(2) {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
}

.more-about-us i {
  position: relative;
  transition: 0.5s;
}

.more-about-us:hover i {
  transform: translateX(15px);
}

.more-about-us:before {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  background: black;
  border-radius: 100%;
  transform: scale(0);
  transition: 0.5s;
}

.more-about-us span {
  position: relative;
}

.more-about-us:hover:before {
  transform: scale(15);
}

ul.slick-dots li {
  width: 8px;
  height: 8px;
  background: #03c0f2;
  border-radius: 100px;
  transition: 0.5s;
}

ul.slick-dots li.slick-active {
  width: 62px;
}

ul.slick-dots li button {
  opacity: 0;
}

section.services {
  background-repeat: no-repeat;
  background-position-x: -303px;
}

section.services h3 { 
  font-weight: 600;
  font-size: 16px;
  color: rgba(0,0,10,0.45);
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}

section.services h3:before {
  content: '';
  width: 100%;
  height: 5px;
  position: absolute;
  background: #03c0f291;
  border-radius: 2.5px;
  left: 0;
  bottom: 4px;
  z-index: -1;
}

section.services h2 {
  line-height: 55px;
  color: #2d2d43;
  font-size: 40px;
  font-weight: 600;
  margin-top: 0;
}

.service-box-1 a {
  font-size: 22px;
  line-height: 24px;
  text-decoration: none;
  color: black;
}

.service-box-1 {
  font-size: 15px;
  line-height: 27px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  position: relative;
  padding: 0;
  box-shadow: 0px 0px 15px #eee;
  padding: 2% 8% 6%;
  text-align: center;
}

.service-box-1 img {
  position: relative;
  left: 0px;
  top: 18px;
  width: 83px;
  margin-bottom: 28px;
}

.service-box-1 p {
  margin-top: 9px;
}

.service-section {
  grid-row-gap: 41px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 43px;
  margin-top: 66px;
}

section.services .container {
  padding-left: 28%;
  padding-right: 8%;
}

.service-box-1 a:hover {
  color: #03c0f2;
}

.service-box-1:hover img {
  transform: scale(0.9);
}

.service-box-1 img {
  transition: 0.5s;
}

.service-box-1 a {
  display: inline-block;
  width: 100%;
}

.portfolioItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-banner-2 {
  padding: 5% 2%;
}

.portfolioItem {
  width: 90%;
  margin: auto;
  box-shadow: 0px 0px 15px #00000036;
  overflow: hidden;
  position: relative;
  height: 290px;
  margin-top:30px;
  padding:13px;
  box-sizing:border-box;
}

.potfolioHoverContent {
  position: absolute;
  background: #000000a3;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: 0.5s;
  transform: scale(0.8);
  opacity: 0;
}

.potfolioHoverContent h4 {
  color: #fff;
  font-size: 24px;
  margin: 0;
  margin-bottom:10px;
}

.potfolioHoverContent a {
  text-decoration: none;
  background: #fff;
  font-weight: bold;
  color: black;
  padding: 5px 20px;
  border-radius: 100px;
  margin-top: 10px;
  font-size: 13px;
}

.portfolioItem:hover .potfolioHoverContent {
  transform: scale(1);
  opacity: 1;
}

.slider-banner-2 ul.slick-dots{
  bottom:-57px;
}

.slider-banner-2 .slick-list {
  padding-bottom: 11px;
}

section.testimonials-wrapper h3 {
  font-weight: 600;
  font-size: 16px;
  color: rgba(0,0,10,0.45);
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}

section.testimonials-wrapper h3:before {
  content: '';
  width: 100%;
  height: 5px;
  position: absolute;
  background: #03c0f291;
  border-radius: 2.5px;
  left: 0;
  bottom: 4px;
  z-index: -1;
}

section.testimonials-wrapper h2 {
  line-height: 55px;
  color: #2d2d43;
  font-size: 40px;
  font-weight: 600;
  margin-top: 0;
}

.testimonial-content {
  background-repeat: no-repeat;
}

section.testimonials-wrapper {
  background-repeat: no-repeat;
  background-position-x: -64%;
}

.testimonial-content {
  padding-left: 50%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 14%;
}

q {
  font-size: 20px;
  line-height: 40px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  padding: 0px 0 0 67px;
  display: inline-block;
  font-style: italic;
  position: relative;
}

.testimonial-item q:before {
  color: #03c0f2;
  position: absolute;
  font-size: 127px;
  font-family: sans-serif;
  font-style: initial;
  left: 0;
  top: 39px;
}

.testimonial-bottom-area img {
  width: 53px;
  position: absolute;
  left: 0;
}

.testimonial-bottom-area {
  position: relative;
  margin-left: 64px;
  margin-top: 27px;
  padding-left: 69px;
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
}

.testimonial-bottom-area strong {
  display: inherit;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  color: #00000a;
  padding-top: 6px;
}

p.subtitle {
  margin-top: -26px;
  margin-bottom: 47px;
  color: #000000a6;
  font-size: 20px;
}

.testimonial-slider ul.slick-dots {
  display: none!important;
}

.testimonial-slider {
  position: relative!important;
}

.testimonial-slider :is(button.slick-arrow.slick-next,button.slick-arrow.slick-prev) {
  opacity: 1;
  transform: none;
  border: none;
}

.testimonial-slider button.slick-arrow.slick-prev:after {
  content: "\f177";
  font: normal normal normal 14px/1 FontAwesome;
  -webkit-text-fill-color: #b9b3b3;
  font-size: 34px;
  bottom: -71px;
  position: absolute!important;
  right: 0!important;
}

.testimonial-slider button.slick-arrow.slick-next:after {
  content: "\f178";
  font: normal normal normal 14px/1 FontAwesome;
  -webkit-text-fill-color: #b9b3b3;
  font-size: 34px;
  bottom: -71px;
  position: absolute;
  right: 0;
}

.testimonial-slider button.slick-arrow.slick-prev {
  position: absolute;
  right: 64px!important;
  left: auto!important;
}

.testimonial-slider button.slick-arrow.slick-prev:hover:after,
.testimonial-slider button.slick-arrow.slick-next:hover:after{
  -webkit-text-fill-color: black;
}

.clientLogo {
    box-shadow: 0px 0px 15px #bbb3b3;
    width: 90%;
    margin: 30px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0px;
}

.clientLogo img {
    height: 103px;
}

.client-slider h2 {
  line-height: 55px;
  color: #2d2d43;
  font-size: 40px;
  font-weight: 600;
  margin-top: 0;
}

.subtitle {
  margin-top: -26px;
  margin-bottom: 47px;
  color: #000000a6;
  font-size: 20px;
}

.client-slider {
  text-align: center;
}

section.footer {
  padding: 13% 0% 0%;
  margin-top: 83px;
}

.footerRow {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.contact-details.footer-widget h3 {
  margin-top: 0;
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
}

.contact-details.footer-widget ul {
  list-style: none;
  padding: 0;
}

.contact-details.footer-widget ul a {
  font-size: 16px;
  text-decoration: none;
  color: #000000b8;
  line-height: 38px;
  font-weight: 500;
}

.contact-details.footer-widget ul a:hover {
  color: #03c4f6;
}

p.footerEmail a {
  font-size: 16px;
  color: #222;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0px;
}

p.footerPhone a {
  font-size: 20px;
  color: #222;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 2px;
}

.footer-copyright {
  border-top: 1px solid #eee;
  padding-top: 19px;
  padding-bottom: 23px;
  font-weight: 500;
  color: #000000bf;
}

.footer-copyright a {
  color: #01c4f7;
}


/* SVG */
.blob {
  max-height: 90vh;
  width: 90vw;
}
.blob path {
  animation: blob 15s linear infinite;
}
@keyframes blob {
  0% {
    d: path("M120,-157.6C152.7,-141.5,174.3,-102.6,194.8,-58.8C215.3,-14.9,234.6,33.8,228.4,80.8C222.2,127.8,190.4,173.1,148.1,184C105.8,195,52.9,171.5,-2.4,174.8C-57.8,178.2,-115.6,208.4,-137.5,190.9C-159.3,173.3,-145.3,108,-153,56.3C-160.7,4.6,-190.2,-33.4,-178.3,-54.2C-166.4,-75.1,-113.2,-78.8,-76.6,-93.6C-40,-108.3,-20,-134.2,11.9,-150.5C43.7,-166.8,87.4,-173.6,120,-157.6Z");
 }
  25% {
    d: path("M67.8,-97.1C87.8,-78.8,103.8,-58.9,117.4,-34.1C130.9,-9.4,142,20.2,139.5,50.7C137,81.2,120.8,112.6,95.3,150.1C69.8,187.7,34.9,231.3,3.3,226.8C-28.2,222.2,-56.4,169.3,-91.6,134.9C-126.8,100.5,-169,84.6,-179.6,57.1C-190.2,29.7,-169.3,-9.3,-155.2,-49.7C-141,-90.1,-133.7,-132,-109,-148.8C-84.2,-165.6,-42.1,-157.3,-9.1,-144.8C23.9,-132.2,47.8,-115.5,67.8,-97.1Z");
 }
  50% {
    d: path("M137.1,-191.3C172,-163.4,190.6,-115.7,197.2,-70.1C203.8,-24.4,198.5,19.2,178.9,51.5C159.3,83.9,125.5,105,93.3,129.6C61.1,154.1,30.6,182.1,1.1,180.6C-28.4,179.1,-56.8,148.2,-81.2,121.1C-105.6,94.1,-126.1,70.8,-141.6,41.6C-157.2,12.4,-168,-22.9,-153.9,-45C-139.8,-67,-100.7,-76,-70.9,-105.5C-41.1,-135,-20.6,-185,15.3,-206C51.1,-227.1,102.3,-219.1,137.1,-191.3Z");
 }
  75% {
    d: path("M123.7,-157.1C162.4,-142.2,197.2,-108.8,202.8,-70.8C208.3,-32.9,184.5,9.7,169,54.2C153.6,98.7,146.4,145.2,119.7,162.7C92.9,180.2,46.4,168.6,-1.9,171.1C-50.2,173.7,-100.3,190.4,-122.2,171.3C-144.1,152.3,-137.7,97.5,-144.1,52.7C-150.6,7.9,-169.9,-26.8,-170.5,-64.8C-171,-102.8,-152.8,-144,-121.3,-161.3C-89.7,-178.5,-44.9,-171.8,-1.2,-170.1C42.5,-168.5,85,-172,123.7,-157.1Z");
 }
  100% {
    d: path("M120,-157.6C152.7,-141.5,174.3,-102.6,194.8,-58.8C215.3,-14.9,234.6,33.8,228.4,80.8C222.2,127.8,190.4,173.1,148.1,184C105.8,195,52.9,171.5,-2.4,174.8C-57.8,178.2,-115.6,208.4,-137.5,190.9C-159.3,173.3,-145.3,108,-153,56.3C-160.7,4.6,-190.2,-33.4,-178.3,-54.2C-166.4,-75.1,-113.2,-78.8,-76.6,-93.6C-40,-108.3,-20,-134.2,11.9,-150.5C43.7,-166.8,87.4,-173.6,120,-157.6Z");
 }
}

section#about-us {
  position: relative;
}

.svgBackground {
  position: absolute;
  bottom: 65px;
  left: -274px;
  transform: scale(1.5);
}

.svgBackground svg path {
  fill: #9ddefa52;
}

span.to-top a:hover {
  border-color: #02c3f6;
}
span.to-top a {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-top: 2px solid black;
  border-right: 2px solid black;
  font-size: 0;
  transform: rotate(-45deg);
}

span.to-top {
  position: absolute;
  right: 10px;
  bottom: 25px;
}

.footer-copyright {
  position: relative;
}

section.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
  box-shadow: 0px 13px 35px -12px rgb(35 35 35 / 15%);
}

header.header-section {
  transition: 0.5s;
}

section.fixed-header header.header-section {
  padding: 0;
}

section.fixed-header header img {
  width: 100px;
  margin-top: 5px;
}

header img {
  transition: 0.5s cubic-bezier(0, 1.15, 0.57, 1.37);
}

section#about-us .content-section a:before {
  position: absolute;
  content: "";
  width: 50px;
  height: 50px;
  border: 2px solid  #03c0f24f;
  opacity: 0.8;
  transform: rotate(45deg);
}

section#about-us .content-section a:after {content: "";position: absolute;width: 50px;height: 50px;border: 2px solid #17191a26;opacity: 0.8;transform: rotate(
45deg);margin-top: 105px;margin-left: 120px;border-radius: 100%;}

section.footer {
  background-repeat: no-repeat;
}

section.footer {
  position: relative;
  z-index: 1;
}

section.footer:before {
  content: "";
  width: 100%;
  height: 120px;
  position: absolute;
  z-index: -1;
  background: #fbfcfd;
  bottom: 0;
}

/*Social*/
.button {
  position: relative;
  width: 50px;
  height: 50px;
}
i {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  z-index: 10;
  color: white;
}
.blurred {
  position: relative;
  top: 0; left: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  filter: url(#goo);
}
.blurred::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: black;
  border-radius: 100%;
  transition: 1.5s cubic-bezier(0,2,.61,-0.05);
}
.blurred::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  background-color: black;
  border-radius: 100%;
  transition: 1.7s cubic-bezier(0,2,.61,-0.05);
}
.blurred > span:nth-of-type(1) {
   position: absolute;
   width: 50px;
   height: 50px;
   top: 0;
   left: 0;
   background-color: black;
   border-radius: 100%;
   transition: 1.5s cubic-bezier(0,2,.61,-0.05);
}
.blurred > span:nth-of-type(2) {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    left: 0;
    background-color: black;
    border-radius: 100%;
    transition: 1.6s cubic-bezier(0,2,.61,-0.05);
}
.button:hover .blurred::before {
    width: 40px;
    height: 40px;
    top: 50%; left: 50%;
}
.button:hover .blurred::after {
    width: 15px;
    height: 15px;
    top: 30px;
    left: 45px;
}
.button:hover .blurred>span:nth-of-type(1) {
     width: 30px;
     height: 30px;
     top: -15px;
     left: -15px;
}
.button:hover > .blurred>span:nth-of-type(2) {
     width: 25px;
     height: 25px;
     top: 40px;
     left: -20px;
}
.social a {
  display: inline-block;
}
/*Social*/


.social {
    width: 87%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

.social a {
    transform: scale(0.8);
    border-radius: 100%;
    margin-right: -9px!important;
}

.social i {
    font-size: 24px;
}

.bottom-filter{
  display: none;
}

.slider-content a {
  position: relative;
}

section#process {
  margin-top: 71px;
}

section#process h2 {
  margin-bottom: 0;
}

.infographic {
  display: inline-block;
  width: 196px;
  height: 196px;
  background: #f8f8f8;
  border-radius: 100%;
  box-shadow: inset -7px 7px 15px #b1aaaa94;
}

.infographic > div {
  width: 100%;
  height: 100%;
  background: conic-gradient(from 0deg, red 0%, red 20%, #f8f8f8 0%, #f8f8f8);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset -7px 7px 15px #34343442;
}

.infographic > div > div {
  width: 70%;
  height: 70%;
  border-radius: 100%;
  background: radial-gradient(circle at 56% 40%, #fcfcfc, #efeff1 53%, rgb(147 147 147) 100%);
  box-shadow: -7px 7px 15px #2c2c2c94;
}

.infographic img {
  max-width: 100%;
}

.infographic > div > div {
  padding: 30px;
  box-sizing: border-box;
}

.infographic-section {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 48px;
}

.infographic-item:nth-child(1) > div > div {
  background: conic-gradient(from 0deg, #9792b0 0%, #9792b0 0%, #f8f8f8 0%, #f8f8f8);
}

.infographic-item:nth-child(2) > div > div {
  background: conic-gradient(from 0deg, #f9ae4a 0%, #f9ae4a 25%, #f8f8f8 0%, #f8f8f8);
}

.infographic-item:nth-child(3) > div > div {
  background: conic-gradient(from 0deg, #f27f61 0%, #f27f61 50%, #f8f8f8 0%, #f8f8f8);
}

.infographic-item:nth-child(4) > div > div {
  background: conic-gradient(from 0deg, #55b6c4 0%, #55b6c4 75%, #f8f8f8 0%, #f8f8f8);
}

.infographic-item:nth-child(5) > div > div {
  background: conic-gradient(from 0deg, #49a4dc 0%, #49a4dc 100%, #f8f8f8 0%, #f8f8f8);
}


.infographic {
  position: relative;
}

.infographic:before {
  content: "";
  width: calc(100% + 16px);
  height: calc(100% + 15px);
  border: 4px solid #dedee0;
  position: absolute;
  border-radius: 100%;
  left: -13px;
  top: -12px;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}

.infographic:after {
  width: 31px;
  height: 3px;
  position: absolute;
  background: #dfdfe1;
  content: "";
  top: calc(50% + -1px);
  left: calc(100% + 9px);
}

.infographic-item:last-child .infographic:after {
  opacity: 0;
}

.infographic-item h4 {
  color: red;
  text-align: center;
  font-size: 23px;
  position: relative;
  margin-top: 56px;
}

.infographic-item h4:before {
  content: "";
  width: 3px;
  height: 42px;
  position: absolute;
  background: #dedee0;
  left: 0;
  right: 0;
  margin: auto;
  top: -47px;
}

.infographic-item h4:after {
  content: "";
  width: 6px;
  height: 6px;
  background: black;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -49px;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 3px #fff, 0px 0px 0px 5px;
}

.infographic-item:nth-child(5) h4 {
  color: #49a2d9;
}

.infographic-item:nth-child(5) h4:after {
  color: #03538d;
  background: #49a2d9;
}

.infographic-item:nth-child(5) h4:before {
  background: #024c86;
}

.infographic-item:nth-child(4) h4 {
  color: #55dcf0;
}

.infographic-item:nth-child(4) h4:after {
  color: #52aab7;
  background: #55dcf0;
}

.infographic-item:nth-child(4) h4:before {
  background: #53b0bd;
}

.infographic-item:nth-child(3) h4 {
  color: #fc542a;
}

.infographic-item:nth-child(3) h4:after {
  color: #ef7e60;
  background: #fc542a;
}

.infographic-item:nth-child(3) h4:before {
  background: #e67a5e;
}

.infographic-item:nth-child(2) h4 {
  color: #ff980f;
}

.infographic-item:nth-child(2) h4:after {
  color: #e8a247;
  background: #ff980f;
}

.infographic-item:nth-child(2) h4:before {
  background: #e19f46;
}

.infographic-item:nth-child(1) h4 {
  color: #01c3f7;
}

.infographic-item:nth-child(1) h4:after {
  color: #03538d;
  background: #01c3f7;
}

.infographic-item:nth-child(1) h4:before {
  background: #024c86;
}

.infographic:hover > div > div {
  transform: scale(1.5);
  transition: 0.5s;
}

.infographic-section {
  margin-top: 63px;
}

.service-box-1:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: #02c3f7;
  left: 0;
  top: 0;
  border-radius: 0;
  transform: scale(0);
  transition: 0.5s;
  z-index: -1;
  top: auto;
  bottom: 0;
}

.service-box-1:hover:before {
  border-radius: 100%;
  transform: scale(1.5);
}

.service-box-1 {
  z-index: 1;
  overflow: hidden;
}

.service-box-1:hover * {
  color: #fff!important;
}

.clientLogo img {
  transition: 0.5s;
}

.clientLogo:hover img {
  transform: scale(1.2);
}

@media (max-width:767px){
  .infographic-section {
      grid-template-columns: repeat(1, 1fr);
  }
  section#about-us .row {
    grid-template-columns: 1fr;
  }
  .service-section{
    grid-template-columns: 1fr;
  }
  .footerRow {
    grid-template-columns: repeat(1, 1fr);
  }
  .testimonial-content {
    padding-left: 30px;
    padding-right: 30px;
  }
  section#about-us img {
    transform: translateX(-1px) translateY(1px);
  }
  section.services .container {
    padding-left: 30px;
    padding-right: 30px;
    background: #fff;
    margin-top: 101px;
  }
  section.footer > div {
    padding-left: 30px;
    padding-right: 30px;
  }
  .infographic {
    margin: auto;
    display: table;
  }
  .infographic-section:after {
    content: "";
    width: 3px;
    height: calc(100% - 314px);
    position: absolute;
    background: #dedee0;
    right: 47px;
    top: 97px;
  }

  .infographic-section {
      position: relative;
  }

  .infographic-item:last-child .infographic:after {
      opacity: 1;
  }
  .slider-banner .image-section {
    right: 0;
    top: 45px;
    position: relative;
    width: 100%;
    height: 261px;
  }
  .slider-banner .container {
    display: flex;
    flex-direction: column;
  }
  .slider-banner .contents-side {
    order: 2;
    padding: 0px 30px 30px;
  }
  .slider-content h2 {
    font-size: 40px;
    line-height: 58px;
    font-weight: 600;
    margin-top: 50px;
  }
  .slider-content h4 br, .container h2 span:before {
    display: none;
  }
  section#about-us .content-section {
    padding: 0px 21px;
  }
  .svgBackground {
    bottom: auto;
    left: 0;
    top: 78px;
  }
}  

/*Mobile Menu*/
@media (max-width:991px){
  section#about-us .content-section {
    padding: 0px 21px;
}

.header-content nav {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    box-sizing: border-box;
}

  nav ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header-content nav {
    display: none;
  }
  .container {
    max-width: 100%;
    margin: auto;
    position: relative;
  }
  .header-content {
    padding: 0px 19px;
  }
  span.menu-opner i {
    color: #04c5f6;
  }
  span.menu-opner {
    position: absolute;
    right: 10px;
    color: #03c3f4;
    width: 87px;
    font-size: 47px;
  }

  header#activeMenu nav {
    display: block;
  }
  nav ul {
    padding: 0;
  }
  .header-content nav {
    left: 0;
  }
  body{
    overflow-x: hidden;
  }
  section.fixed-header {
    overflow-x: hidden;
    box-sizing: border-box;
    position: fixed;
    left: 0;
  }
  .fixed-header span.menu-opner {
    margin-right: 0px;
  }
  nav ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 0px);
    width: calc(100% - 0px);
  }
  header#activeMenu span.menu-opner {
    left: 0;
    right: 0;
    margin: auto;
  }
  .page-not-found .content-section h1 {
    font-size: 131px;
  }
  .page-not-found .row {
    grid-template-columns: 1fr;
    padding: 0% 9%;
    text-align: center;
  }
  .page-not-found .row img{
    display: none;
  }

  .social {
    margin-bottom: 50px;
  }

  .contact-details.footer-widget:nth-child(2) ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 61px;
  }

  .contact-details.footer-widget:nth-child(4) h4 {
    display:none;
  }
  .contact-details.footer-widget:nth-child(3) ul {
    margin-bottom: -21px;
  }
  .page-not-found .row {
    margin-top: -58px;
  }

  body.homepagepage-not-found .fixed-header span.menu-opner {
    margin-right: 0;
  }
  body.homepagepage-not-found  nav ul {
    width: calc(100% - 0px);
  }
} 

@media (min-width:991px){
  .menu-opner, .menu-closer {
    display: none;
  }
}

.header-section:not(#activeMenu) i.fa.fa-times {
  display: none;
}

#activeMenu i.fa.fa-bars{
  display: none;
}

.service-section-2 {
  position: relative;
  width: 462px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 386px;
}

.service-section-2 svg {
  width: 100%;
  position: absolute;
  height: 100%;
}

.content-service {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 0% 13% 0% 24%;
}

.service-section-2 img {
  width: 87px;
}

.service-section-2 h3 {
  color: #fff!important;
  font-size: 23px!important;
  margin-top: 0;
  margin-bottom: 0;
} 

.service-section-2 h3:before {
  display: none;
}

.service-section-2 .content-service p {
  margin-top: 7px;
  font-size: 13px;
  color: #fff;
}
.news-services {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 62px;
  grid-row-gap: 50px;
}

section.services .container {
  padding-left: 20%;
  padding-right: 8%;
}
.news-services a {
  text-decoration: none;
}
.news-services h4 {
  width: 100%;
}

.service-section-2 path{
  transition: 1s;
}

#shap1 tspan { white-space:pre }
#shap1 .s0 { fill: #5c776c } 
#shap1 .s1 { fill: none;stroke: #efd4bc;stroke-width: 4.3 } 
#shap1 .s2 { mix-blend-mode: multiply;fill: url(#grd1) } 
#shap1 .s3 { fill: #66037c; } 
    
.service-section-2:hover > svg path#animPath-1{
    d: path("M 413.15 189.34 C 424.85 224.93 407.41 265.35 375.65 284.09 C 340.07 305.08 294.59 347.493 254.68 341.973 C 212.19 336.103 190.42 328.79 127.93 322.2 C 36.9 312.6 73.347 226.621 85.347 166.391 C 100.277 91.491 103.98 52.38 170.54 47.25 C 228.33 42.8 250.931 107.14 288.911 146.74 C 312.421 171.25 375.92 142.26 398.08 164.36 C 404.42 170.68 409.65 178.67 413.15 189.34 Z");
}

#shap2 tspan { white-space:pre }
#shap2 .s0 { fill: #5c776c } 
#shap2 .s1 { mix-blend-mode: multiply;fill: url(#grd1) } 
#shap2 .s2 { fill: none;stroke: #efd4bc;stroke-width: 4.3 } 
#shap2 .s3 { fill: #333f42; } 
#shap2 .s4 { fill: #ffffff } 

.service-section-2:hover > svg path#animPath-2{
  d: path("M 205.65 27.8 C 239.43 34.89 259.981 70.84 292.691 59.2 C 319.641 49.61 319.275 72.524 341.355 88.617 C 376.304 114.091 387.572 110.483 389.922 146.853 C 392.162 181.593 394.2 208.2 370.72 243.43 C 350.65 273.55 287.25 270.49 251.2 271.67 C 169.44 274.35 145.51 331.051 70.43 276.721 C 27.55 245.691 9.22 227.09 31.05 192.27 C 62.74 141.74 63.199 67.497 139.459 51.167 C 171.679 44.267 175.26 21.42 205.65 27.8 Z");
}

#shap3 tspan { white-space:pre }
#shap3 .s0 { fill: #5c776c } 
#shap3 .s1 { fill: none;stroke: #efd4bc;stroke-width: 4.3 } 
#shap3 .s2 { mix-blend-mode: multiply;fill: url(#grd1) } 
#shap3 .s3 { fill: #1b9ad0 } 
#shap3 .s4 { fill: #ffffff } 

.service-section-2:hover > svg path#animPath-3{
  d: path("M 57.249 230.218 C 61.409 200.648 62.326 177.818 59.886 156.768 C 55.396 117.988 69.511 90.995 96.594 63.349 C 136.758 22.35 163.65 60.3 208.13 61.14 C 230.89 61.57 248.552 75.843 265.311 90.307 C 345.289 159.335 428.1 186 363.98 257.53 C 316.54 310.46 266.82 286.16 218.57 290.13 C 163.55 294.65 119.783 361.112 73.443 308.022 C 50.373 281.592 52.509 263.878 57.249 230.218 Z");
}

#shap4 tspan { white-space:pre }
#shap4 .s0 { fill: #5c776c } 
#shap4 .s1 { fill: none;stroke: #efd4bc;stroke-width: 4.3 } 
#shap4 .s2 { mix-blend-mode: multiply;fill: url(#grd1) } 
#shap4 .s3 { fill: #f57c00 } 
#shap4 .s4 { fill: #ffffff } 


.service-section-2:hover > svg path#animPath-4{
  d: path("M 109.09 274.55 C 96.42 284.7 87 299.31 72.67 306.74 C 16.63 335.77 -1.99 258.86 1.12 217.97 C 4.62 172.03 25 128.67 55.34 95.12 C 115.49 28.62 213.11 4.14 290.69 49.95 C 362.99 92.65 399.02 176.83 364.37 253.46 C 335.43 317.47 256.9 355.88 195.69 109.63 C 371.65 291.47 146.76 251.46 114.76 270.58 C 112.89 271.7 111 273.02 109.08 274.55 Z");
}

.service-section{
  display: none;
}

@media (max-width:767px){
  .news-services {
    grid-template-columns: 1fr;
  }
  .service-section-2 .content-service p {
    display: none;
  }
  .service-section-2 img {
    width: 56px;
  }
  .service-section-2 h4 {
    font-size: 17px!important;
  }
  .service-section-2 {
    width: 100%;
    height: 227px;
  }
  
  section.services .container {
    padding-left: 0;
    padding-right: 0;
  }
  section.services > .container :is(h2,h4) {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.cntNewProcess {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.cntProcessItem svg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.cntProcessItem {
  position: relative;
  height: 209px;
}

.processItemContent {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.processItemContent i {
  margin-top: -17px;
  font-size: 53px;
}

.processItemContent h4 {
  color: #fff;
  font-weight: 400;
  margin-top: 77px;
  letter-spacing: 1px;
}

/*
#ProcessShapOne tspan { white-space:pre }
#ProcessShapOne .s0 { fill: url(#grd1) } 
#ProcessShapOne .s1 { fill: url(#grd2) } 
#ProcessShapOne .s2 { opacity: .3;mix-blend-mode: overlay;fill: #231f20 } 
#ProcessShapOne .s3 { opacity: .6;mix-blend-mode: overlay;fill: #231f20 } 
#ProcessShapOne .s4 { fill: #ffffff } 
*/
#ProcessShapOne tspan { white-space:pre }
#ProcessShapOne .s0 { mix-blend-mode: multiply;fill: #ffa114 } 
#ProcessShapOne .s1 { mix-blend-mode: multiply;fill: #ffc421 } 
#ProcessShapOne .s2 { fill: #ffffff } 

#ProcessShapOne path{
  transition: 0.5s;
}

.cntProcessItem:hover  > svg#ProcessShapOne path#path-1{
  d: path("M 253.15 340.59 C 214.33 354.21 166.68 271.39 144.69 260.49 C 101.54 239.08 27.5 245.96 56.53 196.64 C 69.96 173.82 128.826 128.946 108.43 124.99 C -15.68 100.915 88.06 23.91 125.62 4.97 C 163.036 -13.897 194.88 28.76 231.53 42.83 C 255.81 52.15 281.8 47.54 306.58 52.39 C 323.62 55.72 312.545 134.936 302.625 153.426 C 241.235 267.956 318.99 317.5 253.15 340.59 Z");
}

.cntProcessItem:hover  > svg#ProcessShapOne path#path-2{
  d: path("m140.76 283.46c-64.28-36.54-130.37 1.95-138.87-41.85c-10.47-52.67 43.03-75.17 43.63-116.13c0.6-41.27 1.83-102.93 64.16-64.9c36.83 22.47 93.34-6.33 118.45 32.86c24.49 38.22 33.21 82.91 76.26 94.25c55.84-11.24 63.92 -47.94-3.4 101.98c-61.13 49.07-73.01 83.39-160.23 33.8z");
}

#ProcessShapTwo tspan { white-space:pre }
#ProcessShapTwo .s0 { mix-blend-mode: multiply;fill: #ff8aa9; transition: 0.5s; } 
#ProcessShapTwo .s1 { mix-blend-mode: multiply;fill: #ff355a; transition: 0.5s;} 
#ProcessShapTwo .s2 { fill: #ffffff } 

.cntProcessItem:hover  > :is(svg#ProcessShapTwo, svg#ProcessShapFour) path.s1{
  d: path("m363.1 170.06c0 92.5-90.17 122.6-169.96 122.6c-81.14 0-209.43-47.34-142.28-150.2c115.25-176.55 112.24-210.51 312.24 27.6z");
}

.cntProcessItem:hover  > :is(svg#ProcessShapTwo, svg#ProcessShapFour) path.s0{
  d: path("m136.6 180.57c-17.08-8.7-38.59-9.2-57.71-12.24c-17.81-2.83-50.24-15.25-63.2-28.72c-22.41-23.26-21.06-74.05 12.8-90.28c38.84-18.62 87.65-1.53 129.73 4c64.95 8.53 78.93-41.63 42.1-50.26c24.16-3.3 158.64 3.94 70.47 28.55c15.01 31.23-4.9 97.11 17.62 96.74c8.42 11.08 62.94 57.7 7.29 76.06c-19.92 6.57-40.35 10.83-60.71 15.7c-19.59 4.69-38.64 10.29-56.23 20.24c-23.48 13.28-49.3 33.1-76.33 19.71c-34.78-17.23-34.11-63.32-65.83-79.49z");
}

#ProcessShapThree tspan { white-space:pre }
#ProcessShapThree .s0 { mix-blend-mode: multiply;fill: #5dceff; transition: 0.5s;} 
#ProcessShapThree .s1 { mix-blend-mode: multiply;fill: #5d8eff; transition: 0.5s;} 
#ProcessShapThree .s2 { fill: #ffffff } 

.cntProcessItem:hover  > #ProcessShapThree path.s0{
  d: path("m422.47 118.52c-3.23 46.57-44.22 62.33-78.93 83.98c-35.42 22.1-94.24 43.11-128.02 73.41c-37.64 33.76-54.87 182.76-97.47 77.44c-32.44 3.56-58.59-33.7-60.01-59.93c-3.38-62.35 32.97-101.26 23.62-177.82c-17.03-67.37 10.71-109.16 58.68-107.59c48.92 1.6 85.69 58.32 158.22 50.1c108.74-12.33 136.05 19.56 133.91 50.4z");
} 

.cntProcessItem:hover  > #ProcessShapThree path.s1{
  d: path("m369.15 229.36c-22.56 87-66.85 21.88-104.27 28.57c-73.37-6.48-123.12 91-200.16 20.62c-85.3-55.78-85.76-157.14-0.93-196.08c237.36-108.97 325.84 103.3 305.36 136.89z");
} 

svg#ProcessShapFour .s0 {
  fill: #f9532969;
  transform: rotate(29deg);
  transform-origin: center;
  transition: 0.5s;
}

svg#ProcessShapFour .s2 {
  fill: white;
}

svg#ProcessShapFour .s1 {
  fill: #fa532abd;
  transition: 0.5s;
}

#fifth-process .s1 {
  transform: rotate(5deg);
  transform-origin: center;
}

#fifth-process .s0 {
  transform: rotate(-15deg);
  transform-origin: center;
}

div#fifth-process .s0 {
  fill: #a4b915;
  margin-top: -7px!important;
}

div#fifth-process .s1 {
  fill: #d7f601;
}

.infographic-section{
  display: none;
}

.cntNewProcess {
  margin-top: 49px;
}

@media (max-width:767px){
  .cntNewProcess {
    grid-template-columns: repeat(1, 1fr);
  }
  .cntProcessItem {
    width: 209px;
    position: relative;
    height: 209px;
    margin: auto;
  }
  .content-service {
    padding: 0;
    margin-left: 15%;
  }
  .content-service img {
    margin-left: 36px;
  }
  :is(section.services, section.testimonials-wrapper, .client-slider) h2 {
      line-height: 45px;
      font-size: 37px;
  }
  .testimonial-slider button {
    opacity: 0!important;
  }
  section#process > .container :is(h2, p) {
    padding: 0px 30px;
  }
}

.portfolioItem{
  background: -webkit-linear-gradient(left, rgb(0, 156, 204), rgb(0, 111, 145));
  -webkit-clip-path: url(#maskRect1); 
}

#maskRect1{
  transform:scale(0.80)
}

.portfolioItem{
  padding: 0;
}

.outer-box {
  position: relative;
}

.outer-box > div{
  position:relative;
  z-index:5
}

.outer-box:before {
  transform: scale(1.12);
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 5px;
  background: #0c9ec5;
  -webkit-clip-path: url(#maskRect1);
  }

.outer-box:after {
   transform: scale(1);
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  background: #03c0f2;
  -webkit-clip-path: url(#maskRect1);
}

clipPath#maskRect2 path {
  transform: translate(106px, 88px) scale(1.4);
}

.clientLogo svg {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: scale(2.2);
}

.clientLogo img {
  position: relative;
}

.clientLogo {
  position: relative;
  box-shadow: none;
}

.clientLogo path {
  fill: #f3f9fd;
}

.svg-itrm-2{
  transform:scale(2) rotate(-45deg)!important;
}

svg.svg-itrm-5 {
  transform: scale(1.9) translateX(-9px) translateY(-5px)!important;
}

.clientLogo:hover svg path {
  fill: #27caf2!important;
}
.clientLogo svg path {
  transition: 0.5s;
}
.client-slider .slick-slide > div {
  overflow: hidden;
}

a.btn-1 {
  position: relative;
  filter: url(#goo);
}

a.btn-1 span:nth-child(1) {
  width: 30px;
  height: 30px;
  position: absolute;
  background: #03c0f2;
  border-radius: 100%;
  left: 00px;
  top: 10px;
}

a.btn-1 span:nth-child(2) {
  width: 20px;
  height: 20px;
  position: absolute;
  background: #03c0f2;
  border-radius: 100%;
  right: 10px;
  top: 5px;
}

a.btn-1 span:nth-child(3) {
  width: 10px;
  height: 10px;
  position: absolute;
  background: #03c0f2;
  border-radius: 100%;
  right: 10px;
  bottom: 5px;
}

a.btn-1 span {
  transition: 1.5s cubic-bezier(0,2,.61,-0.05);
}

a.btn-1:hover span:nth-child(1) {
  left: -25px;
  top: -5px;
  transition-delay: 0.2s;
}

a.btn-1:hover span:nth-child(2) {
  right: -29px;
  top: -5px;
  transition-delay: 0.1s;
}

a.btn-1:hover span:nth-child(3) {
  right: -12px;
  bottom: 0;
}

.more-about-us {
  border-radius: 0px 0px 0px 40px;
}

.clientLogo > span {
  display: flex;
  width: 243px;
  height: 243px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  justify-content: center;
}

.client-slider .slick-slide img {
  display: block;
  width: 112px;
  height: auto;
}

.clientLogo:hover > span img {
  transform: scale(1.5);
}
.clientLogo:hover > span {
  transform: scale(0.9);
}

.clientLogo > span {
  transition: 0.5s;
}

.new-portfolio {
  background: url(http://localhost:3000/igt/images/p1.jpg);
  width: 328px;
  background-position: bottom;
  background-size: 100%;
  box-shadow: 0px 0px 15px #0000007a;
  outline: 10px solid #fec11140;
  background-position: bottom;
  background-repeat: no-repeat;
  border-radius: 0px 50px;
  overflow: hidden;
  background-color: #fff;
}

.new-portfolio img {
  transition: 0.5s;
  width: 80%;
  display: table;
  margin: auto;
  margin-bottom: 134px;
  margin-top: 26px;
}

.new-portfolio p {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}

.new-portfolio:hover img {
  transform: translateY(-30px) scale(0.8);
}

.new-portfolio {
  border: 10px solid #fff;
  margin: auto;
  position: relative;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
}

.new-portfolio a {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  background: black;
  color: #fff;
  text-decoration: none;
  width: 109px;
  padding: 8px 22px;
  border-radius: 39px;
  bottom: 49%;
  font-size: 13px;
  opacity: 0;
}

.new-portfolio a i {
  left: 82%;
}

.new-portfolio:hover a {
  transition: 0.5s;
  opacity: 1;
  bottom: 47%;
  transition-delay: 0.2s;
}

.new-portfolio.type_2 {
  outline: 10px solid rgb(100 166 252 / 25%);
}

.new-portfolio.type_3 {
  outline: 10px solid rgb(215 42 74 / 25%);
}

.new-portfolio p {
  color: #fff;
}

.new-portfolio.type_1 a {
  background: #ff9800;
}

.new-portfolio.type_2 a {
  background: #5d92fa;
}

.new-portfolio.type_3 a {
  background: #cf274c;
}

.slider-banner-2 h2 {
  line-height: 55px;
  color: #2d2d43;
  font-size: 40px;
  font-weight: 600;
  margin-top: 0;
  text-align: center;
  margin-bottom: 65px;
}

.slider-banner-2 h4 {
  font-weight: 600;
  font-size: 16px;
  color: rgba(0,0,10,0.45);
  position: relative;
  text-transform: uppercase;
  z-index: 1;
  text-align: center;
  margin: auto;
  display: table;
}

.slider-banner-2 h4:before {
  content: '';
  width: 100%;
  height: 5px;
  position: absolute;
  background: #03c0f291;
  border-radius: 2.5px;
  left: 0;
  bottom: 4px;
  z-index: -1;
}

.slider-banner-2 {
  padding-top: 120px;
}

.portfolio-button {
  text-align: center;
  margin-top: 64px;
}

.menu-item:before {
  content: "";
  width: 17px!important;
  height: 17px;
  border-left: 2px solid;
  border-top: 2px solid;
  background: transparent;
  border-radius: 0;
  transition: 0.5s;
}

.menu-item:after {
  content: "";
  width: 17px!important;
  height: 17px;
  border-right: 2px solid;
  border-bottom: 2px solid;
  background: transparent;
  border-radius: 0;
  position: absolute;
  transition: 0.5s;
}

.menu-item:before {
  top: 4px;
  left: 0;
  opacity: 0;
}

.menu-item:after {
  right: 0;
  bottom: 0;
  opacity: 0;
}

.menu-item:hover:after,
.active:after{
  bottom: -7px;
  right: -15px;
  opacity:1;   
}

.menu-item:hover:before,
.active:before {
  left: -14px;
  top: -6px;
  opacity:1;
}

#shap5 tspan { white-space:pre }
#shap5 .s0 { fill: #5c776c } 
#shap5 .s1 { fill: none;stroke: #efd4bc;stroke-width: 4.3 } 
#shap5 .s2 { mix-blend-mode: multiply;fill: url(#grd1) } 
#shap5 .s3 { fill: #218b67 } 
#shap5 .s4 { fill: #ffffff } 

.service-section-2:hover #animPath-5{
  d:path("m387.7 174.77c32.93 70.09-37.25 201.15-114.47 138.88c-16.29-2.8-30.06-12.96-46.08-17.35c-16.22-4.45-33.28-6.15-50.17-4.92c-30.18 2.2-57.65 1.49-87.67 -14.06c-28.72-9.16-52.33-28.47-65.95-54.8c-23.39-45.23-19.09-104.27 7.47-147.6c46.65-73.36 267.57-102.48 227.39-30.04c22.17 126.85 40.58 143.86 83.99 64.97c18.39 13.66 34.68 29.04 44.49 49.91z");
}

#shap6 tspan { white-space:pre }
#shap6 .s0 { fill: #5c776c } 
#shap6 .s1 { fill: none;stroke: #efd4bc;stroke-width: 4.3 } 
#shap6 .s2 { mix-blend-mode: multiply;fill: url(#grd1) } 
#shap6 .s3 { fill: #e338d3 } 
#shap6 .s4 { fill: #efd4bc } 
#shap6 .s5 { fill: #ffffff } 

.service-section-2:hover #animPath-6{
d: path("m334.55 319.6c-33.69 42.75-77.32 28.01-118.05 22.92c-51.29-6.41-101.42-29.21-134.41-67.82c-32.99-38.61-46.66-93.72-29.37-140.97c23.32-63.71 117.16-94.26 167.73-96.86c79.91-3.21 237.59 55.97 160.75 144.33c17.45 149.26-2.56 208.63-46.65 137.39z");
}


/* Extra Services */
@media (min-width:767px)
{
  .service-section-2 {
    position: relative;
    width: 343px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 321px;
  }
  .service-section-2 .content-service p {
    display: none;
  }
  section.services .container {
    padding-left: 4%;
    padding-right: 4%;
  }

  section.services {
    background: #fff!important;
    padding-bottom: 15%;
  }
  .news-services {
    grid-template-columns: 1fr 1fr 1fr;
  }
  section.services > .container > h2 {
    text-align: center;
  }

  section.services > .container > h3 {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width:767px){
  .portfolio-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
  }
  .new-portfolio {
    box-sizing: border-box;
    width: 80%;
    overflow: hidden;
  }
  .portfolio-button {
    margin-top: 0;
  }
}

html,body{
  overflow-x: hidden;
}

.dark-header section#header:not(.fixed-header) a.menu-item {
  color: #fff;
}

.dark-header .header-content > a.logo img, .dark-header .header-content > a.logo {
  position: relative;
}
.dark-header .header-content > a.logo:after {
  content: "";
  width: 90%;
  height: 90%;
  position: absolute;
  background: white;
  transform: scale(2.3) rotate( 12deg);
  left: -23px;
  border-radius: 18% 82% 71% 29% / 30% 62% 38% 70%;
  top: -23px;
}

.dark-header .header-content > a.logo:before {
  -webkit-transform: scale(2.3);
  transform: scale(2.3);
  content: "";
  width: 90%;
  height: 90%;
  position: absolute;
  background: #874ff7;
  border-radius: 18% 82% 71% 29% / 30% 62% 38% 70%;
  transition: 0.5s cubic-bezier(0,2,.61,-0.05);
}

.fixed-header a.logo:before, .fixed-header a.logo:after{
  transform: scale(0)!important; 
}

.section.servicesGridSection path{
  transition: 0.5s; 
}

section.servicesGridSection .service-section-2 > svg#ServiceShapeTwo .s1 {
  -webkit-transform: translate(477px, 335px) scale(5)!important;
  transform: translate(57%,50%) scale(5)!important;
}

section.servicesGridSection .service-section-2 > svg#ServiceShapeOne .s1{
  transform: translate(37%, 56%) scale(5);
}

section.servicesGridSection .service-section-2:hover > svg#ServiceShapeTwo .s1{
  d: path("M31.8,-49.2C44.9,-47.6,61.6,-46.4,66.4,-38.3C71.2,-30.1,64.1,-15.1,55.6,-4.9C47,5.2,37,10.4,34,21.7C31.1,33.1,35.2,50.7,30.7,63.5C26.3,76.3,13.1,84.4,1,82.7C-11.1,80.9,-22.3,69.4,-33.7,60.6C-45.2,51.8,-57,45.8,-66.2,36.1C-75.3,26.5,-81.9,13.2,-76.7,3C-71.4,-7.2,-54.5,-14.4,-42.5,-19.1C-30.4,-23.8,-23.3,-26,-17.1,-31.5C-10.8,-37,-5.4,-45.9,2,-49.3C9.4,-52.8,18.8,-50.8,31.8,-49.2Z");
  transform: translate(477px, 335px) scale(4.5)!important;
}

section.servicesGridSection .service-section-2:hover > svg#ServiceShapeOne .s1{
  d: path("M35.6,-57.7C47.6,-47.6,60.1,-40.3,69.6,-29C79.2,-17.6,85.9,-2.2,78.9,7.7C71.8,17.6,50.9,21.8,40.2,35.5C29.4,49.3,28.8,72.6,20.2,80.3C11.7,88,-4.8,80.1,-15.5,69.4C-26.3,58.7,-31.4,45.1,-35.4,34.2C-39.4,23.2,-42.3,14.9,-45.6,5.3C-49,-4.2,-52.6,-15,-52.5,-27.6C-52.4,-40.3,-48.5,-54.9,-39.1,-66.3C-29.8,-77.6,-14.9,-85.8,-1.6,-83.3C11.7,-80.9,23.5,-67.8,35.6,-57.7Z");
  transform: translate(35%, 50%) scale(4.4);
}


section.servicesGridSection .service-section-2 > svg#ServiceShapeThree .s1{
  transform: translate(56%,41%) scale(5)!important;
}

section.servicesGridSection .service-section-2:hover > svg#ServiceShapeThree .s1{
  d: path("M46.1,-67.4C60.8,-62.3,74.5,-51.3,77.5,-37.5C80.5,-23.8,72.7,-7.2,69.5,9.4C66.2,26.1,67.5,42.9,60.1,53.1C52.8,63.3,36.8,66.9,21.2,70.9C5.6,75,-9.5,79.3,-21.5,75C-33.6,70.7,-42.5,57.7,-53.9,45.9C-65.2,34.2,-78.9,23.7,-80.7,11.6C-82.5,-0.6,-72.4,-14.5,-64.6,-28.7C-56.9,-43,-51.6,-57.5,-41.2,-64.6C-30.8,-71.7,-15.4,-71.4,0.1,-71.6C15.7,-71.8,31.4,-72.6,46.1,-67.4Z");
  transform: translate(56%,51%) scale(4.4)!important;
}

section.servicesGridSection .service-section-2 > svg#ServiceShapeFour .s1{
  transform: translate(64%,65%) scale(8.5) rotate(88deg)!important;
}

section.servicesGridSection .service-section-2:hover > svg#ServiceShapeFour .s1{
  d: path("M38.8,-39.8C52.2,-35,66.3,-24.6,70.7,-10.8C75,3,69.7,20.1,59,29.8C48.3,39.5,32.2,41.8,18.3,44.2C4.4,46.6,-7.2,49.1,-21.1,48.2C-35,47.4,-51.2,43.2,-60,32.5C-68.8,21.8,-70.2,4.6,-62.4,-6.1C-54.6,-16.7,-37.8,-20.8,-25.9,-25.9C-13.9,-30.9,-7,-36.9,2.9,-40.3C12.7,-43.8,25.4,-44.6,38.8,-39.8Z");
  transform:translate(50%,49%) scale(6.4) rotate(88deg)!important;
}


section.servicesGridSection .service-section-2 > svg#ServiceShapeFive .s1{
  transform: translate(51%,50%) scale(8)!important;
}

section.servicesGridSection .service-section-2:hover > svg#ServiceShapeFive .s1{
  d: path("M37.6,-45.6C53,-31.8,72.8,-24.1,74.9,-13.1C77,-2.1,61.4,12.2,48.8,22.7C36.2,33.1,26.5,39.7,14.1,48.4C1.7,57.1,-13.5,67.9,-26.6,66.1C-39.8,64.4,-51,50.1,-51.3,36.1C-51.6,22.1,-41,8.4,-38.1,-6.5C-35.3,-21.5,-40.1,-37.5,-35.1,-53.2C-30,-68.9,-15,-84.1,-2,-81.7C11.1,-79.4,22.1,-59.5,37.6,-45.6Z");
  transform:translate(49%,55%) scale(6.7)!important;
}


section.servicesGridSection .service-section-2 > svg#ServiceShapeSix .s2{
  transform: translate(62%,57%) rotate(-28deg) scale(8)!important;
}

section.servicesGridSection .service-section-2:hover > svg#ServiceShapeSix .s2{
  d: path("M28.9,-41.6C33.1,-30.8,29.4,-17.7,30.2,-6.3C31.1,5,36.4,14.6,36.8,28C37.1,41.5,32.4,58.8,21.2,66.3C10.1,73.7,-7.5,71.4,-23.8,65.4C-40,59.4,-54.8,49.8,-56.1,37.4C-57.3,24.9,-44.9,9.6,-37.6,-2.3C-30.3,-14.1,-28.2,-22.5,-22.7,-33.2C-17.3,-43.8,-8.7,-56.7,1.8,-58.9C12.3,-61.1,24.6,-52.5,28.9,-41.6Z");
  transform: translate(54%,45%) scale(8)!important;
}

.new-portfolio {
  border: 10px solid #fff;
  margin: auto;
  position: relative;
  margin-bottom: 60px;
}

.new-portfolio.type_4 a {
  background: #1dbe88;
}

.new-portfolio.type_5 a {
  background: #9836af;
}

.new-portfolio.type_6 a {
  background: #ffb262;
}

.new-portfolio.type_4 {
  outline-color: rgb(22 183 129 / 25%);
}

.new-portfolio.type_5 {
  outline-color: rgb(152 54 175 / 25%);
}

.new-portfolio.type_6 {
  outline-color: rgb(255 178 98 / 25%);
}


section.contact {
  font-size: 23px;
  margin-top: -187px;
  width: 100%;
  padding-top: 227px;
  text-align: center;
}

img.bottom-left {
  left: 0;
  position: absolute;
}

.contact-form :is(input,textarea) {
  width: 100%;
  padding: 12px 21px;
  border: 2px solid #925df730;
  margin-bottom: 30px;
  font-size: 17px;
  border-radius: 5px;
  font-family: inherit;
}

.contact-form h2 {
  margin-bottom: -3px;
  margin-top : 30px;
}

.contact-form h2 + p {
  margin-bottom: 31px;
  color: #8d8282;
  font-size:20px
}

.contact-form {
  transform: translateX(62px);
  margin-top:80px;
}

.contact-form * {
  box-sizing: border-box;
}

.contact-form textarea {
  height: 200px;
}

.three-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 28px;
}

.contact-form {
  max-width: 735px;
  margin: auto;
}

section.contact img.bottom-left {
  left: 0;
  position: absolute;
  margin-top: 7%;
}

input[type="submit"] {
  background: #7b67fa;
  color: #fff;
  width: 200px!important;
  display: table!important;
  border-radius: 100px!important;
  margin: auto;
}


.contact-form {
  box-shadow: 0px 0px 15px #eee;
  border-radius: 25px;
  box-sizing: border-box;
  padding: 2% 5%;
  margin-top: 100px;
}

p.message {
  font-size: 16px;
  color: green;
  font-weight: bold;
}

.contact-details h2 {
  line-height: 55px;
  color: #2d2d43;
  font-size: 40px;
  font-weight: 600;
  margin-top: 0;
  text-align: center;
}

section.footer h2 + p {
  position: relative;
  font-size: 16px;
  line-height: 35px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  padding-bottom: 34px;
}

section.contact-details h2 + p {
  position: relative;
  font-size: 16px;
  line-height: 35px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  padding-bottom: 34px;
  text-align: center;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 55px;
  grid-row-gap: 63px;
}

.contact-grid img {
  max-width: 100%;
}


.contact-detalsbox {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 23px;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  background: #fbb831;
  min-height: 320px;
  font-weight: bold;
}

.contact-detalsbox img {
  margin-bottom: 34px;
  height: 164px;
  width: auto!important;
  display: inline-block;
  margin-top: -90px;
  opacity: 0.8;
}

.contact-detalsbox strong {
  font-size: 17px;
}

.contact-detalsbox{
  outline: 2px dashed #fbb8315e;
  outline-offset: 8PX;
}

.contact-grid-item:nth-child(2) > div {
  border-radius: 67% 33% 70% 30% / 60% 67% 33% 40%;
  background: #673ab7;
  outline-color: #673ab752;
}

.contact-grid-item:nth-child(3) > div {
  border-radius: 78% 22% 40% 60% / 73% 61% 39% 27%;
  background: #f34ea9;
  outline-color: hsl(327deg 87% 63% / 28%);
}

.contact-detalsbox, .contact-detalsbox a {
  color: #fff;
  text-decoration: none;
}

.contact-detalsbox {
  position: relative;
}

.contact-detalsbox a:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.contact-grid-item:hover > div {
  border-radius: 100%;
  background: #3a3a3a;
  outline-color: #00000047!important;
}

.contact-grid-item > div {
  transition: 0.5s;
}

section.contact-details {
  padding-top: 71px;
}

button.submit-btn {
  width: 194px;
  height: 56px;
  background: #03c0f2;
  border: none;
  border-radius: 100px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 16px;
  position: relative;
  margin-bottom: 36px;
  transition: 0.5s;
}

button.submit-btn:before {
  content: "";
  width: 78%;
  height: 83%;
  border-radius: 100px;
  background: inherit;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  filter: blur(19px);
  z-index: -1;
  top: 18px;
}

button.submit-btn:hover {
  background: #095993;
  cursor: pointer;
}

.contact-detalsbox:before {
  content: "";
  width: 100%;
  height: 75%;
  position: absolute;
  background: inherit;
  z-index: -1;
  border-radius: inherit;
  bottom: -16px;
  filter: blur(22px);
  opacity: 0.8;
}

.new-portfolio a:before {
  content: "";
  width: 90%;
  height: 91%;
  position: absolute;
  background: inherit;
  left: 0;
  border-radius: 100px;
  z-index: -1;
  filter: blur(15px);
  bottom: -12px;
}

.new-portfolio a {
    z-index: 1;
}

.homepage .service-section-2 .content-service a:before {
  opacity: 0;
}

.awards{
  text-align: center;
  margin-top: 120px;
}
.awards > h2{
  line-height: 55px;
  color: #2d2d43;
  font-size: 40px;
  font-weight: 600;
}
.inner_awards{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.clutch_image{
  border-radius: 50%;
}
.awards_items > div { 
   width: 70%;
   height: 150px;
   border-radius: 5px;
   display: flex;
   justify-content: center;
   align-items: center;
   box-shadow: 0px 11px 25px #e7e7e7;
   transition: all 0.6s;
}
.inner_awards div a > img{
   max-width: 100%;
   max-height: 150px;
   width: 75%;

}

.awards_items > div:hover {
  transform: scale(1.1);
  transition: all 0.6s;
}

.awards_items{
   width: 25%;
   padding: 20px 0px;
   display: flex;
   justify-content: center;
}

@media (max-width:767px){
  .processItemContent h4 {
    font-size: 13px;
  }
  .awards_items{
    width: 100%;
 }
 .awards{
  text-align: center;
  margin-top: 80px;
}
  .more-about-us {
    width: 140px;
  }
  .new-portfolio p {
    font-size: 19px;
  }
  .clientLogo {
    margin: -21px auto 30px;
  }
  img.bottom-left {
    left: -14px!important;
    width: 156px!important;
    top: 14px!important;
  }
  img.top-right {
    right: 0!important;
    top: -57px!important;
    left: auto!important;
    width: 254px!important;
  }
  :is(section.aboutUs,section.services-header) h2 {
    font-size: 32px!important;
    line-height: 41px!important;
  } 
  .bg, .content-service a:before {
    opacity: 0.1;
  }
  .servicesGridSection .content-service a:before {
    left: -11px;
    width: 86px;
    height: 81px;
    top: -15px;
  }
  .servicesGridSection #s1 .content-service {
    margin-left: -2%;
  }
  .servicesGridSection .content-service img {
    margin-left: 0;
  }
  .servicesGridSection div#s4 .content-service {
    margin-left: -2%;
    margin-top: 15px; 
  }
  .servicesGridSection div#s5 .content-service a:before {
    background: #137c59;
    opacity: 1;
  }
  section.aboutUs img {
    position: relative!important;
    right: 0!important;
    bottom: 0!important;
    width: 92%!important;
    z-index: 0!important;
    margin-top: 40px!important;
  }
  section.aboutUs p {
    margin-top: 30px!important;
    margin-bottom: 30px!important;
    font-size: 15px;
  }

  section.aboutUs p br{
    display: none;
  }

  section.aboutUs :is(h2,p,a) {
    margin: 0% 9%;
  }
  section.aboutUs {
    padding-bottom: 204px!important;
  }
  section.our-agency .row {
    grid-template-columns: 100%!important;
  }
  .oa-left-content p {
    margin-left: auto!important;
    margin-right: auto!important;
  }
  .oa-left-content .bg {
    position: relative!important;
    height: 150px;
    opacity: 1;
    margin: auto;
    margin-top: -88px;
  }
  section.our-agency.about-us-content .content-section {
    padding: 0% 6%;
  }
  section.history.about-us-content .row {
    grid-template-columns: 100%!important;
    gap: 0!important;
    padding: 0% 6%!important;
  }
  section.history.about-us-content {
    margin-top: 0!important;
  }

    section.services-header.contact .bg {
      opacity: 1;
  }

  section.services-header.contact img.top-right.aos-init.aos-animate {
      display: none;
  }

  section.services-header.contact .bg img.bottom-left.aos-init.aos-animate {
      left: auto!important;
      right: 0!important;
      transform: rotateY(180deg);
      top: 65px!important;
      z-index: 8;
  }

  .three-column {
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
  }
  .contact-form {
    transform: translateX(0px)!important;
    margin-top: 80px!important;
    width: 89%!important;
    padding-top: 66px!important;
  }
  section.services-header.contact p {
    margin-top: 30px!important;
    margin-bottom: 30px!important;
    font-size: 15px;
  }
 

  .form :is(input,textarea,select) {
    width: 100%;
    padding: 15px 10px;
    font-size: 16px;
    font-family: inherit;
    margin-bottom: 19px;
    border-radius: 8px;
    border: 1px solid #085a9454;
    box-sizing: border-box;
}

.form textarea {
    height: 137px;
}

.form h2 {
    padding: 0;
    padding-bottom: 17px;
    margin-top: 100px;
    margin-bottom: 53px;
}

input[type="file"] {
    margin-top: 6px;
}

.form h2 {
    background: #03c0f2;
    border-radius: 7px;
    padding: 8px 18px;
    border: navajowhite;
    color: #fff;
}

  section.contact-details h2{
    font-size: 32px!important;
    line-height: 41px!important;
  }
  section.contact-details {
    padding: 3% 7% 3%!important;
  }
  .contact-grid {
    grid-template-columns: 1fr!important;
    gap: 75px!important;
  }
}

.blogLoop {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 29px;
  box-sizing: border-box;
}

.inner-blogItem img {
  width: 100%;
}

.inner-blogItem {
  border-radius: 19px;
  overflow: hidden;
}

.blogItem:nth-child(1n) .blogItemsContent {
  background: #4a42ec;
}

.blogItem  .blogItemsContent {
  padding: 24px 25px;
}

.blogItem .blogItemsContent :is(p,a,div) {
  color: #fff;
  text-decoration: none;
}

.blogItem .blogItemsContent h3 {
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  margin: 0;
}

.blogItem .blogItemsContent .date {
  font-size: 12px;
  margin-bottom: 5px;
  opacity: 0.9;
  font-weight: 300;
}

.blogItem .blogItemsContent .date span {
  margin-right: 9px;
}

.blogItem .blogItemsContent .excerpt {
  font-size: 14px;
  line-height: 1.8em;
}

.blogItem:nth-child(2n) .blogItemsContent {
  background: #e91e63;
}

.blogItem:nth-child(3n) .blogItemsContent {
  background: #9c27b0;
}

.blogItem:nth-child(4n) .blogItemsContent {
  background: #2196f3;
}

.blogItem:nth-child(5n) .blogItemsContent {
  background: #4caf50;
}

.blogItem:nth-child(6n) .blogItemsContent {
  background: #ef9209;
}

a.blog-grid-thumbail {
  display: inline-block;
  width: 100%;
  height: 250px;
  overflow: hidden;
  margin-bottom: -8px;
}

a.blog-grid-thumbail img {
  transition: 0.5s;
}

.blogItem:hover a.blog-grid-thumbail img {
  FONT-WEIGHT: 100;
  transform: scale(1.1);
  filter: saturate(0.5);
}

.inner-blogItem{
  box-shadow: 0 15px 15px -10px rgb(23 16 159 / 15%);
}

section.singleBlogWrapper .image-section img {
  max-width: 100%;
  box-shadow: 0 12px 58px 0 rgb(0 31 52 / 35%);
  height: 480px;
  background: #ffffff;
  border-radius: 25px;
  width: 624px;
  height: 429px;
}

.blogHeaderContent {
  display: grid;
  grid-template-columns: 60% 40%;
}

.blogHeaderContent h2 {
  font-size: 41px;
  line-height: 55px;
  font-weight: 600;
  color: #111111;
  margin-top: 0;
}

.image-section {
  position: relative;
}

.image-section:before {
  content: "";
  width: 600px;
  height: 600px;
  position: absolute;
  background: linear-gradient(355deg, #04beef1a, transparent);
  border-radius: 100%;
  right: -16%;
  top: -139px;
}

.blog-bottom-content {
  padding: 6% 18%;
  line-height: 2.1em;
  color: #383636;
}

.image-section img {
  position: relative;
}

.blog-shap {
  width: 300px;
  height: 300px;
  background: #f9aa01;
  position: absolute;
  border-radius: 100%;
  left: 25px;
  top: 139px;
}

.blogHeaderContent {
  padding-top: 77px;
}

.blog-bottom-content q {
  font-style: italic;
  background:#343a40;
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
  color: #fff;
  line-height: 2em;
  border-radius: 18px;
  margin-top: 27px;
  margin-bottom: 29px;
  padding: 7% 13% 7% 21%;
  position: relative;
}

.blog-bottom-content q:before {
  position: absolute;
  left: 5%;
  top: 32%;
  font-size: 221px;
  font-family: sans-serif;
  opacity: 0.5;
}

section.blog-top h2 {
  font-size: 60px;
  line-height: 78px;
  font-weight: 600;
  color: #2d2d43;
  text-align: center;
  margin-bottom: 52px;
}

.blogGrid {
  position: relative;
}

@media (max-width:767px) {
  .blogHeaderContent {
    display: grid;
    grid-template-columns: 100%;
    padding: 0% 10%;
  }
  section.singleBlogWrapper .image-section img {
    width: 100%;
    height: 229px;
    object-fit: cover;
    margin-top: 36px;
  }
  .content-section {
    position: relative;
  }
  .blog-bottom-content {
    padding: 6% 9%;
  }
  .blogHeaderContent h2 {
    font-size: 25px;
    line-height: 37px;
    margin-top: 45px;
  } 
  .blog-shap {
    opacity: 0.1;
  }
  .blog-bottom-content q {
    line-height: 1.8em;
    padding: 7% 9% 7% 26%;
    font-size: 17px;
  } 
  .blog-bottom-content q:before {
    left: 0%;
    top: 15%;
    font-size: 169px;
  }
  .blogLoop {
    grid-template-columns: 1fr;
    padding: 0px 27px;
  }
  a.blog-grid-thumbail {
    height: 205px;
  }
}


section.contact-details h2 + p {
  padding: 0% 23% 5%;
}

.blogHeaderContent {
  align-items: center;
}

/* .contact-grid-item:nth-child(3) .contact-detalsbox {
  border-radius: 76% 24% 70% 30% / 62% 44% 56% 38%;
  background: #0a96d6;
  outline-color: hsl(199deg 91% 44% / 28%)!important;
}

.contact-grid-item:nth-child(4) .contact-detalsbox {
  border-radius: 49% 51% 56% 44% / 62% 71% 29% 38%;
  background: #46c654;
  outline-color: #46c65447;
} */

.contact-grid-item:nth-child(3) .contact-detalsbox {
  border-radius: 27% 73% 56% 44% / 25% 58% 42% 75%;
  background: #0172b1;
  outline-color: rgb(52 91 142 / 28%);
}

.contact-grid-item:hover > div {
  border-radius: 100%;
  background: #3a3a3a!important;
  outline-color: #00000047!important;
  border-radius: 100%!important;
}


.portfolio-filter {
  text-align: center;
}

.portfolio-filter {
  text-align: center;
  margin-bottom: 79px;
}

.portfolio-filter ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  gap: 39px;
}

.portfolio-filter li {
  background: #03afe8;
  color: #fff;
  padding: 6px 33px;
  border-radius: 100px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.portfolio-filter li:before {
  content: "";
  width: 80%;
  height: 50%;
  position: absolute;
  background: inherit;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  border-radius: 100px;
  filter: blur(12px);
  z-index: -1;
}

.hidePortfolio {
  display: none;
}

li.filterList.active {
  background: #04528b;
}

.social a:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  border-radius: 100%;
  transform: scale(1.1);
}

.social a:hover:before {
  opacity: 0;
}

.social {
  width: 87%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 18px;
}

.content-service a h3.last-child:first-letter {
  text-transform: lowercase;
}


@media (max-width:1230px) and (min-width:991px){
  nav a {
    font-size: 12px;
  }
}

@media (min-width:1200px){
  .container {
    max-width: 1180px!important;
    margin: auto;
  }
}

@media (max-width:1200px) and (min-width:1000px){
  .container {
    max-width: 942px!important;
    margin: auto;
  }
}

@media (max-width:1000px){
  .container {
    max-width: calc(100% - 66px)!important;
    margin: auto;
  }
}

@media (min-width:1200px) and (max-width:1260px){
  .container {
    padding: 0px 44px;
  }
}

@media (max-width:991px){
  .slider-content .container {
    display: flex;
    flex-direction: column;
  }
  .slider-content .container .contents-side {
    order: 2;
  }
  .slider-content .container .image-section {
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    padding-right: 0;
  } 
}

@media (max-width:991px) {
  .news-services {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
  } 
  .service-section-2 {
    margin: auto;
  }
  .portfolio-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .testimonial-content {
    padding-left: 6%;
    padding-right: 10%;
    /*padding-top: 57%;*/
    padding-bottom: 14%;
  }
  .cntNewProcess {
    grid-template-columns: repeat(2, 1fr);
  }
  .cntProcessItem {
    position: relative;
    height: 299px;
  }
  section.testimonials-wrapper {
    background-repeat: no-repeat;
    background-position-x: 144%;
    background-size: 97%;
  }
  .footerRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .slider-banner .image-section {
    top: 0px;
    position: relative;
    width: 100%;
    height: 519px;
  } 
  section.services {
    background: #fff!important;
    margin-top: 95px;
  }
  section.our-agency .row {
    grid-template-columns: 100%!important;
  }
  section.history.about-us-content .row {
    grid-template-columns: 100%!important;
  } 
  .blogLoop {
    grid-template-columns: 1fr 1fr!important;
  }
  a.blog-grid-thumbail {
    height: 225px;
  }
  .blogHeaderContent {
    display: grid;
    grid-template-columns: 100%;
    gap: 62px;
  }
  .contact-grid {
    grid-template-columns: 1fr 1fr;
  }
  section.contact img.bottom-left {
    left: -68px;
    position: absolute;
    margin-top: 26%;
  }
}  

@media (max-width:767px) {
  .slider-banner .image-section {
    width: 100%;
    height: 210px;
  }
  .cntNewProcess {
    grid-template-columns: repeat(1, 1fr);
  }
  .cntProcessItem {
    position: relative;
    height: 204px;
  }
  .footerRow {
    grid-template-columns: repeat(1, 1fr);
  }
  .portfolio-filter ul {
    gap: 20px;
    flex-direction: column;
    margin-left: -48px!important;
  }
  .blogLoop {
    grid-template-columns: 1fr!important;
  }
  a.blog-grid-thumbail {
    height: 171px;
  }
  section.contact-details h2 + p {
    padding: 0% 0% 5%;
  }
  .contact-details .container > :is(h2,p) {
    text-align: left;
  }
  .contact-details .container > p:before {
    shape-outside: circle(50%);
    width: 150px;
    height: 81px;
    content: "";
    float: right;
  }
  .contact-details .container {
    max-width: calc(100% - 0px)!important;
  }
}

@media (min-width:1024px) and (max-width:1050px) {
  section.contact img.bottom-left {
    left: -61px;
    position: absolute;
    margin-top: 9%;
  }
  .contact-grid {
    grid-template-columns: 1fr 1fr;
  }
  .contact-detalsbox {
    width: 400px;
    margin: auto;
    height: 400px;
  }
  .blogLoop {
    grid-template-columns: 1fr 1fr;
  }
  .blogHeaderContent .content-section {
    padding-left: 74px;
  }
  .portfolio-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  section.aboutUs img {
    bottom: 30%!important;
  }
  .dark-header .header-content > a.logo:after {
    left: -56px;
  }
  .dark-header .header-content > a.logo:before {
    left: -30px;
  }
  .news-services {
    grid-template-columns: 1fr 1fr;
  }
  .service-section-2 {
    margin: auto;
  }
  .home-portfolio .new-portfolio.type_3:nth-child(3) {
    display: none;
  }
  section.testimonials-wrapper {
    background-repeat: no-repeat;
    background-position-x: -36%;
    background-size: 61%;
    background-position-y: 26%;
  }
  .slider-banner .image-section {
    right: 0;
    top: 176px;
    position: absolute;
    width: 62%;
    height: 393px;
  }
  .slider-content h2 {
    width: 50%;
  }
  .contact-details.footer-widget ul a {
    font-size: 15px;
  }
}

@media (min-width:1400px){
  section.aboutUs {
    background-size: 100% 100%;
  }
  section.aboutUs img {
    bottom: 100px!important;
  }
  section.testimonials-wrapper {
    background-position-x: -25%;
  }
}

a.button.facebook:before {
  background: #4267B2;
}

a.button.facebook .blurred:before, a.button.facebook .blurred:after {
  background: #4267B2;
}

a.button.facebook .blurred span {
  background: #4267B2;
}

a.button.linkedin:before {
  background: #0e76a8;
}

a.button.linkedin .blurred:before, 
a.button.linkedin .blurred:after {
  background: #0e76a8 ;
}

a.button.linkedin .blurred span{
  background: #0e76a8 ;
}


a.button.instagram:before {
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}

a.button.instagram .blurred:before, 
a.button.instagram .blurred:after {
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%) ;
}

a.button.instagram .blurred span{
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%) ;
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes drop {
  0% {
    bottom: 0px;
    opacity: 1;
  }
  
  80% {
    opacity: 1;
  } 

  95% {
    border-radius: 56% 44% 100% 0% / 100% 44% 56% 0%;
  } 
  
  100% {
    opacity: 1;
    bottom: -200px;
    border-radius: 56% 44% 100% 0% / 100% 44% 56% 0%;
    
  }
}

.btn-3 {
  width: 150px;
  height: 50px;
  display: inline-block;
  background: #11a7c1;
  position: relative;
  filter: url(#goo);
  border-radius: 100px;
}


a.menu-item {
  position: relative;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

span.btn-3 {
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0px;
  background: #03c0f2;
  transform: scale(0);
  transition: 0.5s;
}

a.menu-item span.menu-text {
  position: relative;
  display: inherit;
  text-align: center;
  width: 100%;
  top: 0;
}

.menu-item:hover span.btn-3, a.menu-item.active span.btn-3 {
  transform: scale(1);
  overflow: initial;
}

.menu-item:hover:before, .active:before,
.menu-item:hover:after, .active:after{
  opacity: 0;
}

nav ul {
  list-style: none;
  display: flex;
  grid-gap: 38px;
  gap: 9px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

nav li {
  text-align: center;
}

nav li a.active:before, nav li a:hover:before{
  opacity: 0;
}

a.menu-item:hover, a.menu-item.active  {
  color: #fff;
}
nav:hover a.menu-item span.btn-3 span{
  display: none;
 }

 nav:hover a.menu-item:hover span.btn-3 span{
  display: block;
}

.popup {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: none;
  z-index: 2147483647;
  background: #ffffff47;
  display: none;
  backdrop-filter: blur(8px);
  align-items: center;
  justify-content: center;
}

#activePopup{
  display: flex;
}

.activePopup{
  display: flex;
}

.innerPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #303436d9;
  padding: 6px 79px 58px;
  border-radius: 13px;
}

.innerPopup h2 {
  color: #fff;
  font-size: 42px;
}

.innerPopup a {
  background: #fff;
  width: 59%;
  text-align: center;
  margin-bottom: 12px;
  text-decoration: none;
  color: #000;
  padding: 13px 14px;
  border-radius: 37px;
  font-weight: bold;
  cursor: pointer;
}

@media (min-width:767px){
  .slide-3 .slider-box > div:first-child {
    order: 2;
  }
}

.slide-3 .sliderBanner {
  transform: rotateY(180deg);
}

section.history.about-us-content h3 {
  color: #2cd3ff;
}

@media (min-width:991px) and (max-width:1200px){
  a.menu-item {
    position: relative;
    padding: 12px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
}

@media (min-width:674px) and (max-width:767px){
  .innerProcessBox:before {
    width: 132px!important;
    height: 132px!important;
  }

  .processBox-1:nth-child(odd) h3  {
    top: -154px!important;
  }

  .processBox-1 i {
    width: 80px!important;
    height: 80px!important; 
    font-size: 30px!important;
  }

  .processBox-1:nth-child(odd) h3 {
    top: -135px!important;
    font-size: 12px!important;
  }

  .processBox-1:nth-child(even) h3 {
    bottom: -135px!important;
  }
}



.services-201 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
}

.services-201 img {
  max-width: 193px;
}
.servicesInfoBox h3:before{
  opacity:0;
}
.servicesInfoBox h3 {
  font-size: 27px!important;
  text-align: center;
  color: black!important;
  line-height: 1.3em;
  text-transform: capitalize!important;
}

.servicesInfoBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 340px;
  position: relative;
  z-index: 1;
  max-width: 340px
}

.servicesInfoBox:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.servicesInfoBox:before {
  background: rgb(234 53 56 / 65%);
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  transform: rotate(-40deg);
}

.servicesInfoBox {
  border-radius: 32% 68% 70% 30% / 30% 72% 28% 70%;
  background: #ffd1d2;
}

.inner-services:nth-child(1) > div:before {
  background: rgb(255 225 182 / 84%);
}

.inner-services:nth-child(1) > div {
  background: rgb(255 162 33 / 84%);
}

.inner-services:nth-child(1)  img {
  width: 228px;
  max-width: initial;
  margin-bottom: -26px;
}

.inner-services:nth-child(2) > div:before {
  background: rgb(130 223 180 / 84%);
  transform: rotate(-56deg);
}

.inner-services:nth-child(2) > div {
  background: rgb(0 151 82 / 84%);
}

.inner-services:nth-child(4) > div:before {
  background: #fc9af1a3;
  transform: rotate(-56deg);
}

.inner-services:nth-child(4) > div {
  background: #f921e1a3
}

.inner-services:nth-child(5) > div:before {
  background: rgb(158 155 255 / 84%);
}

.inner-services:nth-child(5) > div {
  background: rgb(49 46 141 / 84%);
}

.inner-services:nth-child(6) > div:before {
  background: rgb(255 164 195 / 84%);
}

.inner-services:nth-child(6) > div {
  background: rgb(233 30 99 / 98%); 
}

.inner-services:nth-child(3) > div:before {
  background: rgb(255 180 184 / 84%);
}

.inner-services:nth-child(3) > div {
  background: #f95555; 
}

.inner-services:nth-child(3) > div img {
  max-width: initial;
  width: 266px;
}

.servicesInfoBox:before {
  transition: 0.5s;
}

.servicesInfoBox:hover:before {
  border-radius: 73% 27% 70% 30% / 64% 88% 12% 36%;
}

.servicesInfoBox {
  transition: 0.5s;
}

.servicesInfoBox:hover {
  border-radius: 73% 27% 70% 30% / 30% 82% 18% 70%;
}

.services-201 {
  margin-top: 90px;
}

.servicesInfoBox {
  margin: auto;
}

@media (min-width:1300px){
  .portfolio-grid {
    max-width: 1300px;
    margin: auto;
  }
}

@media (max-width:1200px)
{
  .services-201 {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 149px;
  }
  .cntNewProcess-2 {
    margin-bottom: 175px;
  }
  .cntNewProcess-2 {
    min-height: 300px;
    margin-top: 61px!important;
  }   
  section.testimonials-wrapper {
    background-position-x: -113%;
  }
  .clientLogo{
    transform: scale(0.8)!important;
    width: 106%;
  }
}

@media (max-width:767px)
{
  .services-201 {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 149px; 
  }
}

@media (min-width:1500px)
{
  .testimoialImage img {
    width: 85%!important;
  }
}

@media (max-width:1200px) and (min-width:991px)
{
  .testimoialImage {
    top: 91px!important;
  }
}

@media (max-width:991px)
{
  .testimoialImage {
    display:none;
  }
}


.testimoialImage {
  position: absolute;
  top: -91px;
  width:50%;
  right:50%;
}

section.testimonials-wrapper {
  position: relative;
}
.testimoialImage img{
  width: 100%;
}


a.close {
  background: transparent;
  position: relative;
}

a.close span {
  width: 31px;
  height: 2px;
  background: red;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 43px;
}

a.close span:nth-child(2) {
  transform: rotate(-45deg);
}

a.close span:nth-child(1) {transform: rotate(45deg);}

a.close:before {
  left: 0;
  right: 0;
  width: 50px;
  height: 50px;
  position: absolute;
  background: red;
  content: "";
  margin: auto;
  border-radius: 100%;
  top: 19px;
  opacity: 0;
}

a.close:hover:before {
  opacity: 1;
}

a.close:hover span {
  background: #fff;
}

a.btn-01 {
  background: #03c0f2;
  color: #fff;
}

a.btn-01:before, a.btn-02:before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  background: #014b86;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  border-radius: 100%;
  z-index: -1;
  transform: scale(0);
  transition: 0.5s;
}

a.btn-01, a.btn-02 {
  position: relative;
  z-index: 5;
  overflow: hidden;
}

a.btn-01:hover:before, a.btn-02:hover:before {
  transform: scale(24);
}

a.btn-02:hover {
  color: #fff;
}


section#career h2 {
  text-align: center;
  font-size: 60px;
  line-height: 78px;
  font-weight: 600;
  color: #2d2d43;
}

section#career h3 {
  text-align: center;
  text-transform: capitalize;
  color: #2d2d43;
  font-size: 28px;
  margin-top: -58px;
  font-weight: 300;
}

.itemCareer img {
  width: 103px;
}

.itemCareer {
  text-align: left;
  box-shadow: 0px 0px 15px #eee;
  padding: 17px 25px;
}

.itemCareer h3 {
  display: inline-block;
  width: 100%;
  text-align: left!important;
  font-weight: bold!important;
  font-size: 21px!important;
  margin-top: 23px!important;
  margin-bottom: 0;
}

.itemCareer h3 + p {
  font-size: 15px;
  line-height: 1.7em;
  color: #000000b3;
}

.content-section.careerLoop {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 33px;
}

.itemCareer {
  position: relative;
  background: #fff;
}



.steps {
  display: flex;
  justify-content: space-around;
}

.steps p {
  width: 50px;
  aspect-ratio: 1/1;
  background: #baadad;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  position: relative;
  z-index: 5;
  transition: all 1.5s cubic-bezier(0,2,.61,-0.05);
}

.stepsForm[form-step-position="1"] p:nth-child(1) {
  background: #03b2ea;
}

.steps:before {
  width: 100%;
  height: 5px;
  position: absolute;
  background: #baadad;
  content: "";
  top: 50%;
}

.steps:after {
  width: 100%;
  height: 5px;
  position: absolute;
  background: #03b2ea;
  content: "";
  top: 50%;
  left: 0;
  transition: all 1.5s cubic-bezier(0,2,.61,-0.05);
}

.steps {
  position: relative;
}

.stepsForm[form-step-position="1"] .steps:after {
  width: 9%;
}

.stepsForm[form-step-position="2"] .steps:after {
  width: 30%;
}

.stepsForm[form-step-position="3"] .steps:after {
  width: 49%;
}

.stepsForm[form-step-position="4"] .steps:after {width: 69%;}

.stepsForm[form-step-position="5"] .steps:after {
  width: 90%;
}

.stepsForm[form-step-position="6"] .steps:after {
  width: 100%;
}

.stepsForm[form-step-position="2"] .steps :is(p:nth-child(1),p:nth-child(2)) {
  background: #03b2ea;
}

.stepsForm[form-step-position="3"] .steps :is(p:nth-child(1),p:nth-child(2),p:nth-child(3)) {
   background: #03b2ea;
}

.stepsForm[form-step-position="4"] .steps :is(p:nth-child(1),p:nth-child(2),p:nth-child(3),p:nth-child(4)) {
   background: #03b2ea;
}

.stepsForm[form-step-position="5"] .steps :is(p:nth-child(1),p:nth-child(2),p:nth-child(3),p:nth-child(4),p:nth-child(5)) {
   background: #03b2ea;
}

.stepsForm[form-step-position="6"] .steps :is(p:nth-child(1),p:nth-child(2),p:nth-child(3),p:nth-child(4),p:nth-child(5)) {
   background: #03b2ea;
}

div#form1 h2:before {
  content: "";
  background: #03b2ea;
  position: absolute;
  width: 5px;
  height: 6px;
  left: 58px;
  font-size: 37px;
  border-radius: 100%;
  bottom: -8px;
}

.stepFormInner h2 {
    background: transparent!important;
    color: #111!important;
    margin-top: 21px!important;
    position: relative;
    padding-left: 0px!important;
}

.stepFormInner h2:after {
    content: "";
    width: 50px;
    height: 6px;
    background: #04abe1;
    position: absolute;
    left: 0;
    bottom: -8px;
}


.formBottomNavigation p{
  width: 194px;
      height: 56px;
      background: #03c0f2;
      border: none;
      border-radius: 100px;
      color: #fff;
      font-weight: bold;
      letter-spacing: 2px;
      font-size: 16px;
      position: relative;
      margin-bottom: 36px;
      transition: 0.5s;
      margin: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  
  .formBottomNavigation p:before {
      content: "";
      width: 78%;
      height: 83%;
      border-radius: 100px;
      background: inherit;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      -webkit-filter: blur(19px);
      filter: blur(19px);
      z-index: -1;
      top: 18px;
  }

  .formBottomNavigation {
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.inputForm {
  display: none;
}

.stepsForm[form-step-position="1"] {
  display: block;
}

.stepsForm[form-step-position="1"] div#form1 {
  display: block;
}

.stepsForm[form-step-position="2"] div#form2 {
  display: block;
}

.stepsForm[form-step-position="3"] div#form3 {
  display: block;
}

.stepsForm[form-step-position="4"] div#form4 {
  display: block;
}

.stepsForm[form-step-position="5"] div#form5 {
  display: block;
}

.stepsForm[form-step-position="6"] div#form6 {
  display: block;
}


.form :is(input,textarea,select) {
  width: 100%;
  padding: 15px 10px;
  font-size: 16px;
  font-family: inherit;
  margin-bottom: 19px;
  border-radius: 8px;
  border: 1px solid #085a9454;
  box-sizing: border-box;
}

.form textarea {
  height: 137px;
}

.form h2 {
  padding: 0;
  padding-bottom: 17px;
  margin-top: 100px;
  margin-bottom: 53px;
}

input[type="file"] {
  margin-top: 6px;
}

.input-radio label {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
}

.input-radio input {
  position: absolute;
  left: 0;
  width: 30px;
  opacity: 0;
}

.input-radio label:before {
    content: "";
    width: 12px;
    aspect-ratio: 1/1;
    position: absolute;
    border: 2px solid #776b6b;
    border-radius: 100%;
    top: 4px;
    left: 0;
    transition: 0.5s;
}

.input-radio label:after {
    content: "";
    width: 8px;
    aspect-ratio: 1/1;
    position: absolute;
    border-radius: 100%;
    background: #03c0f2;
    left: 4px;
    top: 8px;
    transition: 0.5s;
    transform: scale(0);
}

.input-radio input:checked + label:after {
    transform: scale(1);
}

.input-radio input:checked + label:before {
    border-color: #03c0f2;
}

.radio-button {
  position: relative;
}

.formBottomNavigation :is(p,button) {
  text-transform: uppercase;
}

.stepFormInner h2 {
  border: none!important;
}

@media (max-width:767px){
  .steps p {
    font-size: 11px;
    width: 32px;
  }
  .steps:after, .steps:before {
    top: 48%;
  }
  .formBottomNavigation {
      flex-direction: column;
  }
}