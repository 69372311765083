.itemCareer .fa {
    color: #05558f;
    left: 0;
    font-size: 24px;
    top: 16px;
}

.itemCareer h3 {
    position: relative;
    padding-left: 24px;
}

.itemCareer {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
}

.btnSection {
    transition: 0.5s;
    position: absolute;
    top: 0;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: #15181800;
    align-items: center;
    justify-content: center;
    display: flex;
    backdrop-filter: blur(3px);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    z-index: 88;
    border: 2px solid #03c0f2;
    border-radius: inherit;
    opacity: 0;
}

.btnSection a.btn-1 {
    border-radius: 100px;
    position: relative;
    padding: 1px 34px;
}


.itemCareer:hover .btnSection {
    opacity: 1;
}


.itemCareer p {
    color: black!important;
    font-size: 18px!important;
    margin-bottom: 0!important;
    margin-top: 0!important;
}

.itemCareer p span {
    color: #918e8e!important;
}

.itemCareer h3 {
    margin-bottom: 12px;
}

.itemCareer {
    padding: 8px 36px 30px;
    box-shadow: 0px 0px 38px rgb(0 0 0 / 29%);
}

section#career h3 {
    margin-bottom: 17px;
    margin-left: 13px;
    position: relative;
    padding-bottom: 10px;
}

section#career h3:after {
    content: "";
    width: 96%;
    height: 2px;
    position: absolute;
    left: -14px;
    bottom: -4px;
    border-bottom: 2px dashed #c8c8c8;
}

section.careerInfo .container {
    padding: 1% 22%;
}

.careerSingle {
    padding: 2% 5%;
    box-shadow: 0px 0px 129px #0000002b;
    border-radius: 15px;
}

.careerSingle h2 {
    position: relative;
    padding-left: 68px;
    border-bottom: 3px dashed #30333330;
    padding-bottom: 21px;
}

.careerSingle h2 .fa {
    color: #045c98;
    position: absolute;
    left: 21px;
    font-size: 44px;
    top: 20px;
}

.meta p {
    margin: 0;
}

.meta p strong {
    padding-right: 10px;
    display: inline-block;
}

.meta {
    border-bottom: 3px dashed #30333330;
    padding-bottom: 22px;
}


ul.skillist li {
    position: relative;
    border-bottom: 1px solid #eee;
    padding: 9px 0px;
    list-style: none;
}

ul.skillist li:before {
    transform: rotate(45deg);
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #07558d;
    position: absolute;
    border-radius: 0;
    left: -28px;
    top: 16px;
    
}

ul.skillist li:hover:before {
    border-radius: 100%;
    border-right-color: transparent;
    border-top-color: transparent;
}

ul.skillist li:after {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    border: 2px solid #055690;
    left: -33px;
    top: 11px;
    border-left-color: transparent;
    opacity:0;
}

ul.skillist li:hover:after{
    animation:anim1 1s linear infinite;
    opacity:1;
}

ul.skillist li:hover:before{
    animation:anim1 1.2s linear infinite;
}

@keyframes anim1 {
    0%{
        transform:rotate(0deg);
    }
    100%{
        transform:rotate(360deg);
    }
}


.row.careerPage {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 83px;
    margin-top: 27px;
}

.row.careerPage p {
    line-height: 2em;
}

.row.careerPage img {
    position: relative;
    margin-top: 39px;
}

.row.careerPage h2 {
    text-align: left!important;
}

.careerSingle h2 a {
    position: absolute;
    right: 0;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    background: #04beef;
    padding: 6px 18px;
    border-radius: 100px;
}

.careerSingle h2 a i {
    font-size: 15px!important;
    color: #03c0f2;
    position: absolute!important;
    left: 21px!important;
    opacity: 0;
    transition: 0.5s;
}

.careerSingle h2 a:hover {
    background: transparent;
    color: black;
}

.careerSingle h2 a:hover i {
    left: 3px!important;
    opacity: 1;
}

.row.careerPage img {
    max-width: 100%;
}

@media (max-width:991px) {
    .row.careerPage {
        grid-template-columns: 1fr;
    }
}

@media (max-width:991px) and (min-width:661px) {
    .content-section.careerLoop {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 33px;
        gap: 33px;
    }
}

@media (max-width:661px) {
    .content-section.careerLoop {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 33px;
        gap: 33px;
    }
}


@media (max-width:1000px) {
    .careerSingle h2 a i {
        opacity: 1;
    }
    .careerSingle h2 a {
        font-size: 0;
        background: transparent;
    }
}

@media (max-width:767px) {
    section.careerInfo .container {
        padding: 1% 1%;
    }
}


form.form :is(input,textarea,select) {
    width: 100%;
    padding: 15px 10px;
    font-size: 16px;
    font-family: inherit;
    margin-bottom: 19px;
    border-radius: 8px;
    border: 1px solid #085a9454;
    box-sizing: border-box;
}

form.form textarea {
    height: 137px;
}

form.form h2 {
    padding: 0;
    padding-bottom: 17px;
    margin-top: 100px;
    margin-bottom: 53px;
}

input[type="file"] {
    margin-top: 6px;
}

form.form h2 {
    background: #03c0f2;
    border-radius: 7px;
    padding: 8px 18px;
    border: navajowhite;
    color: #fff;
}

p.success {
    border: 1px solid green;
    color: green;
    padding: 11px 16px;
}

.progress i{
    color:green;
}

p.progress {
    position: relative;
}

p.progress i {
    top: -49px;
    font-size: 27px;
    color: #03c0fc;
    animation: round 1s linear infinite;
    transform:rotate(0deg)
}

@keyframes round{
    0%{
        transform:rotate(-360deg)
    }
}